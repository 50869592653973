import jwt_decode from 'jwt-decode'

const TOKEN_KEY = 'repasse-medico-token'
const USERNAME_KEY = 'username'
const USER_PROFILE = 'profile'
const HOSPITAL = 'hospital'

export const isAuthenticated = () =>
  localStorage.getItem(TOKEN_KEY) !== null && !isTokenExpired()

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY)
}

export const getTokenExpirationDate = (token: string) => {
  const decoded: { exp: number | undefined } = jwt_decode(token)

  if (!decoded.exp) return null

  const date = new Date(0)

  date.setUTCSeconds(decoded.exp)

  return date
}

export const isTokenExpired = () => {
  const token = getToken()

  if (!token) return true

  const date = getTokenExpirationDate(token)

  if (date === null || date === undefined) return false

  return !(date.valueOf() > new Date().valueOf())
}

export const getUserId = (): number => {
  const token = getToken()

  const decoded: { sub: string } = jwt_decode(token as string)

  return Number.parseInt(`${decoded.sub}`)
}

export const setUsername = (username: string) => {
  localStorage.setItem(USERNAME_KEY, username)
}

export const getUsername = () => {
  return localStorage.getItem(USERNAME_KEY)
}

export const setUserProfile = (profile: string) => {
  localStorage.setItem(USER_PROFILE, profile)
}

export const getUserProfile = (): string | null => {
  return localStorage.getItem(USER_PROFILE)
}

export const setHospitalAdmin = (hospital: string) => {
  localStorage.setItem(HOSPITAL, hospital)
}

export const getHospitalAdmin = (): string | null => {
  return localStorage.getItem(HOSPITAL)
}

export const logout = () => {
  removeToken()
  localStorage.removeItem(USER_PROFILE)
  localStorage.removeItem(USERNAME_KEY)
}
