import { FunctionComponent, useState } from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
} from 'react-bootstrap'
import classnames from 'classnames'

import HeaderOutside from 'components/layout/headerOutside'
import Footer from '../../../components/layout/footer'
import resetPasswordCss from './passwordReset.module.scss'
import * as api from '../api.client'
import { AppMessage } from '../../../utilities/messages'

const PasswordRecovery: FunctionComponent = () => {
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })
  const [email, setEmail] = useState<string>('')
  const [recoveringPassword, setRecoveringPassword] = useState(false)

  const recoverPassword = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formIsValid = event.currentTarget.checkValidity()

    if (!formIsValid) {
      setShowFormErrors(true)
      return
    }

    setRecoveringPassword(true)

    try {
      await api.recoverPassword(email)

      setAppMessage({
        show: true,
        success: true,
        message: 'Enviamos um email com instruções para redefinir sua senha.',
      })
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: (error as any).messages.join(', '),
      })
    } finally {
      setRecoveringPassword(false)
    }
  }

  const handleFormChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(target.value)
  }

  return (
    <div>
      <HeaderOutside></HeaderOutside>

      <Container fluid className={resetPasswordCss.container}>
        <Row
          className={classnames(
            'justify-content-md-center',
            resetPasswordCss.row
          )}
        >
          <Col md="4" className={resetPasswordCss.col}>
            <Card className="mb-2">
              <Card.Body>
                <h5 className={resetPasswordCss.title}>Redefinir senha</h5>

                {appMessage.show && (
                  <Alert
                    variant={appMessage.success ? 'success' : 'danger'}
                    style={{ marginTop: 20 }}
                  >
                    {appMessage.message}
                  </Alert>
                )}

                <Form
                  noValidate
                  validated={showFormErrors}
                  onSubmit={recoverPassword}
                >
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      className={resetPasswordCss.formControl}
                      name="email"
                      value={email}
                      onChange={handleFormChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Informe um email válido.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className={resetPasswordCss.button}
                  >
                    {recoveringPassword && (
                      <div>
                        <Spinner size="sm" animation="border" variant="dark" />{' '}
                        Processando...
                      </div>
                    )}

                    {!recoveringPassword && <div>Redefinir senha</div>}
                  </Button>

                  <Row>
                    <Col sm="12" className={resetPasswordCss.backToLogin}>
                      <a href="/login" className={resetPasswordCss.backToLogin}>
                        Voltar para o login
                      </a>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  )
}

export default PasswordRecovery
