import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Container,
  Button,
  Row,
  Form,
  Breadcrumb,
  Spinner,
  Alert,
} from 'react-bootstrap'

import * as api from './api.client'
import styles from './style.module.scss'
import { NewHospitalDoctor } from './types'
import { removeAlphabeticCharacteres } from '../../utilities/string.util'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { AppMessage } from '../../utilities/messages'
import { formatCpf, validateCpf } from '../../utilities/cpf.util'

const newDoctorInitialState: NewHospitalDoctor = {
  name: '',
  email: '',
  cpf: '',
  notifyUserNow: true,
}

const AddDoctor: FunctionComponent = () => {
  const navigate = useNavigate()
  const [doctor, setDoctor] = useState(newDoctorInitialState)
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [saving, setSaving] = useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })

  const addDoctor = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formIsValid = event.currentTarget.checkValidity()

    if (!formIsValid) {
      setShowFormErrors(true)
      return
    }

    setSaving(true)

    try {
      await api.addDoctor({
        ...doctor,
        cpf: removeAlphabeticCharacteres(doctor.cpf),
      })

      navigate(`/doctors`, {
        state: {
          newDoctorAdded: true,
          email: doctor.email,
          userNotified: doctor.notifyUserNow,
        },
      })
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: `Erro ao adicionar o médico: ${(error as any).messages.join(
          ', '
        )}`,
      })
    } finally {
      setSaving(false)
    }
  }

  const handleFormChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = target

    setDoctor({ ...doctor, [name]: type === 'checkbox' ? checked : value })
  }

  return (
    <Container fluid className={styles.container}>
      {appMessage.show && (
        <Alert
          variant="danger"
          style={{ marginTop: 20 }}
          onClose={() => setAppMessage({ show: false })}
          dismissible
        >
          {appMessage.message}
        </Alert>
      )}

      <Row>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/doctors')}>
            Doctors
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add doctor</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Form noValidate validated={showFormErrors} onSubmit={addDoctor}>
        <Form.Group className="mb-3" controlId="formNome">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nome"
            name="name"
            value={doctor.name}
            onChange={handleFormChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe o nome do médico.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            value={doctor.email}
            onChange={handleFormChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe o email do médico.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCPF">
          <Form.Label>CPF</Form.Label>
          <Form.Control
            type="text"
            placeholder="CPF"
            name="cpf"
            value={formatCpf(doctor.cpf)}
            onChange={handleFormChange}
            minLength={14}
            maxLength={14}
            required
            isValid={validateCpf(formatCpf(doctor.cpf))}
          />
          <Form.Control.Feedback type="invalid">
            Informe um CPF válido.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formNotifyUserNow">
          <Form.Check
            type="switch"
            name="notifyUserNow"
            label="Notificar o médico agora?"
            checked={doctor.notifyUserNow}
            onChange={handleFormChange}
          />
          <Form.Text className="text-muted">
            Caso ainda esteja validando os repasses, não envie a notificação
            sobre a criação da conta agora.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          {saving && (
            <div>
              <SaveIcon />{' '}
              <span className={styles.txtButtonAddHospital}>Salvar</span>{' '}
              <Spinner animation="border" size="sm" />{' '}
            </div>
          )}
          {!saving && (
            <div>
              <SaveIcon />{' '}
              <span className={styles.txtButtonAddHospital}>Salvar</span>
            </div>
          )}
        </Button>
      </Form>
    </Container>
  )
}

export default AddDoctor
