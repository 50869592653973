import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Container,
  Table,
  Button,
  Row,
  Col,
  Spinner,
  Alert,
} from 'react-bootstrap'

import hospitalsCss from './style.module.scss'
import * as api from './api.client'
import { Plus, Trash, PencilSquare } from 'react-bootstrap-icons'
import { formatCNPJ } from '../../utilities/cnpj.util'
import ConfirmationModal from '../../components/modal/confirmationModal'
import { AdminHospital } from './types'

const AdminHospitals: FunctionComponent = () => {
  const navigate = useNavigate()
  const [hospitals, setHospitals] = useState<AdminHospital[]>([])
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [errorMessage, setErrorMessage] = useState({ show: false, message: '' })
  const [removingHospital, setRemovingHospital] = useState(false)
  const [removingHospitalId, setRemovingHospitalId] = useState<number>()

  useEffect(() => {
    getHospitals()
  }, [])

  const getHospitals = async () => {
    try {
      const hospitals = await api.getHospitals()
      setHospitals(hospitals)
    } catch (error) {
      setErrorMessage({
        show: true,
        message: 'Erro ao buscar os hospitais.',
      })
    }
  }

  const addHospital = () => navigate('/hospitals/new')

  const editHospital = (id: number) => navigate(`/hospitals/${id}`)

  const removeHospital = async () => {
    if (!removingHospitalId) {
      return
    }

    try {
      setRemovingHospital(true)
      await api.removeHospital(removingHospitalId)
      await getHospitals()
    } catch (error) {
      setErrorMessage({
        show: true,
        message: 'Erro ao remover o hospital.',
      })
    } finally {
      setRemovingHospital(false)
      setShowRemoveConfirmation(false)
      setRemovingHospitalId(undefined)
    }
  }

  return (
    <Container fluid className={hospitalsCss.container}>
      {errorMessage.show && (
        <Alert variant="danger" style={{ marginTop: 20 }}>
          {errorMessage.message}
        </Alert>
      )}

      {showRemoveConfirmation && (
        <ConfirmationModal
          message="Você deseja realmente remover o hospital?"
          btnMessage="Remover"
          btnVariant="outline-danger"
          onClose={() => setShowRemoveConfirmation(false)}
          onConfirmation={removeHospital}
        />
      )}

      <Row>
        <Col>
          <h3 className={hospitalsCss.title}>Hospitais</h3>
        </Col>

        <Col className={hospitalsCss.header}>
          <Button size="sm" onClick={addHospital}>
            <Plus size={26} />{' '}
            <span className={hospitalsCss.txtButtonAddHospital}>
              Add hospital
            </span>
          </Button>
        </Col>
      </Row>

      <Table striped hover responsive="sm">
        <thead>
          <tr>
            <th>Hospital</th>
            <th>CNPJ</th>
            <th>Endereço API</th>
            <th className={hospitalsCss.tableActions}></th>
          </tr>
        </thead>
        <tbody>
          {hospitals.map((hospital, index) => (
            <tr key={index}>
              <td>{hospital.name}</td>
              <td>{formatCNPJ(hospital.cnpj)}</td>
              <td>{hospital.hospitalApiUrl}</td>
              <td>
                <Button
                  className={hospitalsCss.removeButton}
                  variant="outline-danger"
                  size="sm"
                  onClick={() => {
                    setShowRemoveConfirmation(true)
                    setRemovingHospitalId(hospital.id)
                  }}
                >
                  {removingHospital && removingHospitalId === hospital.id && (
                    <div>
                      <Trash size={16} /> <span>Remover</span>{' '}
                      <Spinner animation="border" size="sm" />{' '}
                    </div>
                  )}
                  {(!removingHospital ||
                    removingHospitalId !== hospital.id) && (
                    <div>
                      <Trash size={16} /> <span>Remover</span>
                    </div>
                  )}
                </Button>

                <Button
                  className={hospitalsCss.actionButtons}
                  variant="outline-primary"
                  size="sm"
                  onClick={() => editHospital(hospital.id)}
                >
                  <PencilSquare size={16} /> <span>Editar</span>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default AdminHospitals
