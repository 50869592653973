import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Card,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Modal,
  Form,
  Table,
  Spinner,
  Nav,
  Alert,
} from 'react-bootstrap'
import { Search, Download } from 'react-bootstrap-icons'
import Chart from 'react-google-charts'
import classnames from 'classnames'
import { format } from 'date-fns'

import CurrencyLabel from 'components/util/currencyLabel'
import { TransferIndicators } from './transferIndicators'
import { UserRole } from 'features/user/types'

import DatePicker from 'components/forms/datePicker'

import transferCss from './styles.module.scss'

import * as transferApi from './api.client'
import * as hospitalApi from '../hospitals/api.client'
import * as doctorApi from '../doctors/api.client'
import { ConvenioOptions, Transfer, TransferStatus } from './types'
import { HospitalAvailable } from 'features/hospitals/types'
import { Doctor } from 'features/doctors/types'
import { exportarCsv } from 'utilities/csv.util'
import { AccessRequestStatus } from '../accessRequest/types'
import { getUserProfile, getUsername } from '../user/auth'

const statusColors = new Map()
statusColors.set(TransferStatus.PAGO, '#2CAF7D')
statusColors.set(TransferStatus.LIBERADO, '#21a9f5')
statusColors.set(TransferStatus.PROCESSANDO, '#36c3d9')
statusColors.set(TransferStatus.PENDENTE, '#F7C61C')
statusColors.set(TransferStatus.GLOSADO, '#D6103B')

const ALL_SELECTED = 'All'
const MASK = 'dd-MM-yyyy'

const Transfers: FunctionComponent = () => {
  const navigate = useNavigate()

  const [transfers, setTransfers] = useState<Transfer[]>([])
  const [transfersForChart, setTransfersForChart] = useState<any[][]>([])
  const [transferStatusSelected, setTransferStatusSelected] = useState(
    TransferStatus.PAGO
  )
  const [transferConvenioSelected, setTransferConvenioSelected] =
    useState(ALL_SELECTED)
  const [indicators, setIndicators] = useState<TransferIndicators>(
    new TransferIndicators()
  )
  const [transfersLoaded, setTransfersLoaded] = useState(true)
  const [hospitals, setHospitals] = useState<HospitalAvailable[]>([])
  const [doctors, setDoctors] = useState<Doctor[]>([])
  const [selectedHospitalId, setSelectedHospitalId] = useState(0)
  const [selectedDoctorId, setSelectedDoctorId] = useState(0)
  const [filter, setFilter] = useState({ start: new Date(), end: new Date() })
  const [openReportDetail, setOpenReportDetail] = useState(false)
  const [chartColors, setChartColors] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState({ show: false, message: '' })

  const setTransfersAndBuildIndicators = useCallback(
    (transfers: Transfer[]) => {
      const indicators = TransferIndicators.build(
        transfers,
        transferConvenioSelected
      )

      setTransfers(transfers)
      setIndicators(indicators)
      setTransferStatusSelected(TransferStatus.PAGO)
      buildAndSetTransfersForChart(indicators, TransferStatus.PAGO)
    },
    [transferConvenioSelected]
  )

  const loadTransfersByHospital = useCallback(
    async (hospitalId: number, startDate: Date, endDate: Date) => {
      setTransfersLoaded(false)

      try {
        const transfers = await transferApi.getTransfersByHospital(
          hospitalId,
          format(startDate, MASK),
          format(endDate, MASK)
        )
        setTransfersAndBuildIndicators(transfers)
      } catch (error) {
        setTransfersLoaded(true)
        const errorMessage = 'Ocorreu um erro ao consultar os repasses médicos.'
        console.error(errorMessage, error)
        setErrorMessage({
          show: true,
          message: errorMessage,
        })
      }
    },
    [setTransfersAndBuildIndicators]
  )

  const loadHospitals = useCallback(async () => {
    const initialFilterDates = getInitialFilterDates()

    const hospitals = await hospitalApi.getAvailableHospitals()

    const hospitalDoctorHasAccess = hospitals.filter(
      (hospital) =>
        AccessRequestStatus[hospital.accessRequestStatus] ===
        AccessRequestStatus.APPROVED.toString()
    )

    const firstHospitalId =
      hospitalDoctorHasAccess.length === 0 ? 0 : hospitalDoctorHasAccess[0].id

    setHospitals(hospitalDoctorHasAccess)
    setSelectedHospitalId(firstHospitalId)
    setFilter({
      start: initialFilterDates[0],
      end: initialFilterDates[1],
    })

    loadTransfersByHospital(
      firstHospitalId,
      initialFilterDates[0],
      initialFilterDates[1]
    )
  }, [loadTransfersByHospital])

  const loadTransfersByDoctor = useCallback(
    async (doctorId: number, startDate: Date, endDate: Date) => {
      setTransfersLoaded(false)

      try {
        const transfers = await transferApi.getTransfersByDoctor(
          doctorId,
          format(startDate, MASK),
          format(endDate, MASK)
        )
        setTransfersAndBuildIndicators(transfers)
      } catch (error) {
        setTransfersLoaded(true)
        const errorMessage = 'Ocorreu um erro ao consultar os repasses médicos.'
        setErrorMessage({
          show: true,
          message: errorMessage,
        })
      }
    },
    [setTransfersAndBuildIndicators]
  )

  const loadDoctors = useCallback(async () => {
    const initialFilterDates = getInitialFilterDates()
    const doctors = await doctorApi.getDoctorsRelatedToHospital()
    const firstDoctorId = doctors.length === 0 ? 0 : doctors[0].id

    setDoctors(doctors)
    setSelectedDoctorId(firstDoctorId)
    setFilter({
      start: initialFilterDates[0],
      end: initialFilterDates[1],
    })

    loadTransfersByDoctor(
      firstDoctorId,
      initialFilterDates[0],
      initialFilterDates[1]
    )
  }, [loadTransfersByDoctor])

  const getInitialFilterDates = (): [Date, Date] => {
    const now = new Date()
    const yearAgo = new Date()
    yearAgo.setFullYear(yearAgo.getFullYear() - 1)

    return [yearAgo, now]
  }

  const loadTransfers = (startDate: Date, endDate: Date) => {
    if (selectedHospitalId && loggedUserIsDoctor()) {
      loadTransfersByHospital(selectedHospitalId, startDate, endDate)
    }

    if (selectedDoctorId && !loggedUserIsDoctor()) {
      loadTransfersByDoctor(selectedDoctorId, startDate, endDate)
    }
  }

  const buildIndicatorsByConvenio = (convenio: string) => {
    const indicators = TransferIndicators.build(transfers, convenio)
    setIndicators(indicators)
    setTransferStatusSelected(TransferStatus.PAGO)
    buildAndSetTransfersForChart(indicators, transferStatusSelected)
  }

  const updateTransfersOnChart = (status: TransferStatus) => {
    buildAndSetTransfersForChart(indicators, status)
  }

  const buildAndSetTransfersForChart = (
    indicators: TransferIndicators,
    transferStatus: TransferStatus
  ) => {
    let transfersTotalsByMonth =
      indicators.getTotalGroupedByMonthAndYearFrom(transferStatus)

    if (transfersTotalsByMonth.size === 0) {
      setTransfersLoaded(true)
      setTransfersForChart([])

      return
    }

    let chartDescription = new Array<any>()
    chartDescription.push('Mês/Ano com base na data do fechamento do protocolo')
    chartDescription.push('')

    let transfersForChart = new Array<Array<any>>(chartDescription)

    Array.from(transfersTotalsByMonth.entries()).forEach((entry) => {
      let transferOfMonth: Array<any> = new Array<any>()
      transferOfMonth.push(entry[0])
      transferOfMonth.push(entry[1])
      transfersForChart.push(transferOfMonth)
    })

    const chartColors = []
    chartColors.push(statusColors.get(transferStatus) as string)

    setTransfersForChart(transfersForChart)
    setTransfersLoaded(true)
    setChartColors(chartColors)
    setTransferStatusSelected(transferStatus)
  }

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputValue?: any
  ) => {
    const { name, value } = event.currentTarget

    setFilter({
      ...filter,
      [name]: inputValue !== undefined ? inputValue : value,
    })
  }

  const loggedUserIsDoctor = (): boolean => getUserProfile() === UserRole.DOCTOR

  const changeConvenio = (convenio: string) => {
    setTransferConvenioSelected(convenio)
    buildIndicatorsByConvenio(convenio)
  }

  const changeSelectedHospital = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.currentTarget
    setSelectedHospitalId(Number.parseInt(value))
  }

  const changeSelectedDoctor = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.currentTarget
    const selectedDoctorId = Number.parseInt(value)

    setSelectedDoctorId(selectedDoctorId)
    loadTransfersByDoctor(selectedDoctorId, filter.start, filter.end)
  }

  const openHospitalPage = () => navigate('/hospitals')

  const downloadAll = () => {
    downloadCsv(transfers.sort((t1, t2) => (t1.status > t2.status ? 1 : -1)))
  }

  const downloadByStatus = () => {
    downloadCsv(
      transfers.filter(
        (transfer) =>
          transfer.status === transferStatusSelected &&
          (transferConvenioSelected === ALL_SELECTED ||
            transfer.convenio === transferConvenioSelected)
      )
    )
  }

  const downloadCsv = (transfers: Array<Transfer>) => {
    let arquivoCsv: string =
      'Numero Repasse;Atendimento;Status;Convenio;Procedimento;Data da Alta;Data Protocolo;Valor\n'

    transfers.forEach((t: Transfer) => {
      arquivoCsv +=
        t.numeroRepasseTerceiro === null
          ? 'N/A;'
          : t.numeroRepasseTerceiro + ';'
      arquivoCsv +=
        t.numeroAtendimento === null ? 'N/A;' : t.numeroAtendimento + ';'
      arquivoCsv += t.status + ';'
      arquivoCsv += t.descricaoConvenio + ';'
      arquivoCsv +=
        (t.descricaoProcedimento !== null
          ? t.descricaoProcedimento
          : t.observacaoRepasse) + ';'
      arquivoCsv += t.dataAlta + ';'
      arquivoCsv +=
        (transferStatusSelected !== TransferStatus.PAGO
          ? t.dataProtocolo
          : t.dataFechamentoRepasse) + ';'
      arquivoCsv += t.valorRepasse + ';\n'
    })

    exportarCsv(arquivoCsv, 'repasses')
  }

  useEffect(() => {
    if (loggedUserIsDoctor()) {
      loadHospitals()
    }

    if (!loggedUserIsDoctor()) {
      loadDoctors()
    }
  }, [loadDoctors, loadHospitals])

  return (
    <Container fluid className={transferCss.contentHeigth}>
      {loggedUserIsDoctor() && selectedHospitalId === 0 && (
        <Alert variant="success" className={transferCss.noAccessMessage}>
          <Alert.Heading>Olá {getUsername()}</Alert.Heading>
          Você ainda não possui acesso aos repasses de nenhum hospital.
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => openHospitalPage()}
              variant="outline-success"
            >
              Solicitar acesso
            </Button>
          </div>
        </Alert>
      )}

      {!loggedUserIsDoctor() && selectedDoctorId === 0 && (
        <Alert variant="success" className={transferCss.noAccessMessage}>
          <Alert.Heading>Olá {getUsername()}</Alert.Heading>
          Selecione um médico para consultar os repasses.
        </Alert>
      )}

      {errorMessage.show && (
        <Alert variant="danger" style={{ marginTop: 20 }}>
          {errorMessage.message}
        </Alert>
      )}

      {(selectedHospitalId > 0 || selectedDoctorId > 0) && (
        <Form
          style={{
            marginTop: '10px',
          }}
        >
          <Row>
            <Col xs={3} className={transferCss.headerColSelect}>
              {loggedUserIsDoctor() && (
                <Form.Select
                  className="mb-2 mr-sm-2"
                  onChange={changeSelectedHospital}
                >
                  {hospitals.map((hospital, index) => (
                    <option key={index} value={hospital.id}>
                      {hospital.name}
                    </option>
                  ))}
                </Form.Select>
              )}

              {!loggedUserIsDoctor() && (
                <Form.Select
                  className="mb-2 mr-sm-2"
                  onChange={changeSelectedDoctor}
                >
                  {doctors?.map((doctor, index) => (
                    <option key={index} value={doctor.id}>
                      {doctor.name}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Col>

            <Col xs="auto" className={transferCss.headerCol}>
              <DatePicker
                name="start"
                value={filter.start}
                onChange={handleFilterChange}
                placeholder="Data inicial"
              ></DatePicker>
            </Col>

            <Col xs="auto" className={transferCss.headerCol}>
              <DatePicker
                name="end"
                value={filter.end}
                onChange={handleFilterChange}
                placeholder="Data final"
              ></DatePicker>
            </Col>

            <Col xs="auto" className={transferCss.headerCol}>
              <ButtonGroup>
                <Button
                  type="button"
                  className={classnames('mb-2', transferCss.buttonSearch)}
                  variant="secondary"
                  onClick={() => loadTransfers(filter.start, filter.end)}
                >
                  {!transfersLoaded && (
                    <div>
                      <Spinner animation="border" size="sm" /> Buscando...
                    </div>
                  )}
                  {transfersLoaded && (
                    <div>
                      <Search /> Buscar repasses
                    </div>
                  )}
                </Button>

                <Button
                  type="button"
                  className={classnames('mb-2', transferCss.buttonSearch)}
                  variant="secondary"
                  onClick={downloadAll}
                >
                  <div>
                    <Download />
                  </div>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
      )}

      {!loggedUserIsDoctor() &&
        transfersLoaded &&
        transfersForChart.length === 0 && (
          <Alert variant="warning" className={transferCss.noAccessMessage}>
            Não encontramos nenhum repasse.
          </Alert>
        )}

      {selectedHospitalId > 0 && (
        <Nav variant="pills" style={{ marginBottom: '3px' }}>
          <Nav.Item>
            <Button
              type="button"
              variant="secondary"
              className={
                transferConvenioSelected === ALL_SELECTED
                  ? transferCss.buttonSelectedConvenio
                  : transferCss.buttonConvenio
              }
              onClick={() => changeConvenio(ALL_SELECTED)}
            >
              Todos
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              type="button"
              variant="secondary"
              className={
                transferConvenioSelected === ConvenioOptions.SUS
                  ? transferCss.buttonSelectedConvenio
                  : transferCss.buttonConvenio
              }
              onClick={() => changeConvenio(ConvenioOptions.SUS)}
            >
              SUS
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              type="button"
              variant="secondary"
              className={
                transferConvenioSelected === ConvenioOptions.NAO_SUS
                  ? transferCss.buttonSelectedConvenio
                  : transferCss.buttonConvenio
              }
              onClick={() => changeConvenio(ConvenioOptions.NAO_SUS)}
            >
              Convênio
            </Button>
          </Nav.Item>
        </Nav>
      )}

      {loggedUserIsDoctor() &&
        selectedHospitalId !== 0 &&
        transfersLoaded &&
        transfersForChart.length === 0 && (
          <Alert variant="warning" className={transferCss.noAccessMessage}>
            Não encontramos nenhum repasse.
          </Alert>
        )}

      {transfersLoaded && transfersForChart.length > 0 && (
        <Row>
          <Col
            className={classnames(
              transferCss.cardCol,
              transferCss.cardPendingPadding
            )}
          >
            <Card
              className={classnames('mb-2', transferCss.headerCard)}
              onClick={() => updateTransfersOnChart(TransferStatus.PENDENTE)}
            >
              <Card.Body>
                <Card.Title className={transferCss.cardTitle}>
                  PENDENTE
                </Card.Title>
                <Card.Text className={transferCss.pendingCardText}>
                  <CurrencyLabel>
                    {indicators.getTotalByStatus(TransferStatus.PENDENTE)}
                  </CurrencyLabel>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className={classnames(
              transferCss.cardCol,
              transferCss.cardGlossedAndReleasedPadding
            )}
          >
            <Card
              className={classnames('mb-2', transferCss.headerCard)}
              onClick={() => updateTransfersOnChart(TransferStatus.GLOSADO)}
            >
              <Card.Body>
                <Card.Title className={transferCss.cardTitle}>
                  GLOSADO
                </Card.Title>
                <Card.Text className={transferCss.glossedCardText}>
                  <CurrencyLabel>
                    {indicators.getTotalByStatus(TransferStatus.GLOSADO)}
                  </CurrencyLabel>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className={classnames(
              transferCss.cardCol,
              transferCss.cardProcessingPadding
            )}
          >
            <Card
              className={classnames('mb-2', transferCss.headerCard)}
              onClick={() => updateTransfersOnChart(TransferStatus.PROCESSANDO)}
            >
              <Card.Body>
                <Card.Title className={transferCss.cardTitle}>
                  FILA DE PAGAMENTO
                </Card.Title>
                <Card.Text className={transferCss.processingCardText}>
                  <CurrencyLabel>
                    {indicators.getTotalByStatus(TransferStatus.PROCESSANDO)}
                  </CurrencyLabel>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className={classnames(
              transferCss.cardCol,
              transferCss.cardGlossedAndReleasedPadding
            )}
          >
            <Card
              className={classnames('mb-2', transferCss.headerCard)}
              onClick={() => updateTransfersOnChart(TransferStatus.LIBERADO)}
            >
              <Card.Body>
                <Card.Title className={transferCss.cardTitle}>
                  LIBERADO
                </Card.Title>
                <Card.Text className={transferCss.releasedCardText}>
                  <CurrencyLabel>
                    {indicators.getTotalByStatus(TransferStatus.LIBERADO)}
                  </CurrencyLabel>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className={(transferCss.paidCardCol, transferCss.cardPaidPadding)}
          >
            <Card
              className={classnames('mb-2', transferCss.headerCard)}
              onClick={() => updateTransfersOnChart(TransferStatus.PAGO)}
            >
              <Card.Body>
                <Card.Title className={transferCss.cardTitle}>PAGO</Card.Title>
                <Card.Text className={transferCss.paidCardText}>
                  <CurrencyLabel>
                    {indicators.getTotalByStatus(TransferStatus.PAGO)}
                  </CurrencyLabel>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {transfersLoaded && transfersForChart.length > 0 && (
        <Row>
          <Col>
            <Card className={transferCss.chartCard}>
              <Row>
                <Col xs={7}>
                  <h5
                    className={classnames(
                      transferCss.mainLabelsColor,
                      transferCss.chartTitle
                    )}
                  >
                    Detalhamento mensal dos repasses{' '}
                    <span
                      style={{
                        color: statusColors.get(transferStatusSelected),
                      }}
                    >
                      {transferStatusSelected}
                    </span>
                  </h5>
                </Col>

                <Col xs={5} className={transferCss.reportColButton}>
                  <ButtonGroup>
                    <Button
                      variant="secondary"
                      onClick={() => setOpenReportDetail(true)}
                      style={{
                        backgroundColor: statusColors.get(
                          transferStatusSelected
                        ),
                        borderColor: statusColors.get(transferStatusSelected),
                      }}
                      className={transferCss.reportButton}
                    >
                      Relatório
                    </Button>

                    <Button
                      variant="secondary"
                      onClick={() => downloadByStatus()}
                      style={{
                        backgroundColor: statusColors.get(
                          transferStatusSelected
                        ),
                        borderColor: statusColors.get(transferStatusSelected),
                      }}
                      className={transferCss.reportButton}
                    >
                      <div>
                        <Download />
                      </div>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>

              <Modal
                dialogClassName={transferCss.reportModal}
                size="lg"
                show={openReportDetail}
                onHide={() => setOpenReportDetail(false)}
                scrollable={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title className={transferCss.mainLabelsColor}>
                    Relatório de repasses
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Repasse</th>
                        <th>Atendimento</th>
                        <th>Status</th>
                        <th>Convênio</th>
                        <th>Procedimento</th>
                        <th>Data da alta</th>
                        {transferStatusSelected !== TransferStatus.PAGO && (
                          <th>Data do protocolo</th>
                        )}
                        {transferStatusSelected === TransferStatus.PAGO && (
                          <th>Data do fechamento</th>
                        )}
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transfers
                        .filter(
                          (transfer) =>
                            transfer.status === transferStatusSelected &&
                            (transferConvenioSelected === ALL_SELECTED ||
                              transfer.convenio === transferConvenioSelected)
                        )
                        .map((transfer, index) => (
                          <tr key={index}>
                            <td>{transfer.numeroRepasseTerceiro}</td>
                            <td>{transfer.numeroAtendimento}</td>
                            <td>{transfer.descricaoStatus}</td>
                            <td>{transfer.descricaoConvenio}</td>
                            <td>
                              {transfer.descricaoProcedimento !== null
                                ? transfer.descricaoProcedimento
                                : transfer.observacaoRepasse}
                            </td>
                            <td>{transfer.dataAlta}</td>
                            <td>
                              {transferStatusSelected !== TransferStatus.PAGO
                                ? transfer.dataProtocolo
                                : transfer.dataFechamentoRepasse}
                            </td>
                            <td>
                              <CurrencyLabel>
                                {transfer.valorRepasse}
                              </CurrencyLabel>
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td colSpan={7}></td>
                        <td>
                          <b>
                            <CurrencyLabel>
                              {indicators.getTotalByStatus(
                                transferStatusSelected
                              )}
                            </CurrencyLabel>
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal.Body>
              </Modal>

              <Chart
                height={'350px'}
                chartType="Line"
                data={transfersForChart}
                options={{
                  colors: chartColors,
                  legend: { position: 'none' },
                }}
              />
            </Card>
          </Col>
        </Row>
      )}

      {transfersLoaded && transfersForChart.length > 0 && (
        <Row>
          <Col
            className={classnames(
              transferCss.footerCardCol,
              transferCss.cardAtendPadding
            )}
          >
            <Card className={transferCss.footerCard}>
              <Card.Body>
                <Card.Title>Atendimentos</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Atendimentos realizados no período
                </Card.Subtitle>
                <Card.Text>
                  {indicators.totalAttendances} atendimentos
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col
            className={classnames(
              transferCss.footerCardCol,
              transferCss.cardPmrPadding
            )}
          >
            <Card className={transferCss.footerCard}>
              <Card.Body>
                <Card.Title>PMR</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Prazo médio de recebimento
                </Card.Subtitle>
                <Card.Text>{indicators.averageReceiptPeriod} dias</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col
            className={classnames(
              transferCss.footerCardCol,
              transferCss.cardTicketPadding
            )}
          >
            <Card className={transferCss.footerCard}>
              <Card.Body>
                <Card.Title>Ticket</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Ticket médio de receita
                </Card.Subtitle>
                <Card.Text>
                  <CurrencyLabel>
                    {indicators.averageRevenueTicket}
                  </CurrencyLabel>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Transfers
