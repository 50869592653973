import { FunctionComponent, useEffect, useState } from 'react'
import {
  Container,
  Table,
  Button,
  Badge,
  Spinner,
  Alert,
} from 'react-bootstrap'

import hospitalsCss from './styles.module.scss'
import * as api from './api.client'
import {
  AccessRequest,
  AccessRequestStatus,
  AccessRequestStatusLabel,
  UpdateAccessRequestStatus,
} from './types'

const AccessRequestPage: FunctionComponent = () => {
  const [accessRequests, setAccessRequests] = useState<AccessRequest[]>([])
  const [updatingAccessRequest, setUpdatingAccessRequest] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  useEffect(() => {
    loadAccessRequests()
  }, [])

  const loadAccessRequests = async () => {
    const accessRequests = await api.getAccessRequests()
    setAccessRequests(
      accessRequests.filter(
        (a) =>
          AccessRequestStatus[a.status] ===
          AccessRequestStatus.REQUESTED.toString()
      )
    )
  }

  const approveAccessRequest = async (accessRequestId: number) => {
    setUpdatingAccessRequest(true)

    await api.updateAccessRequest(
      accessRequestId,
      UpdateAccessRequestStatus.APPROVED
    )

    setShowSuccessMessage(true)
    setUpdatingAccessRequest(false)
    loadAccessRequests()
  }

  const denyAccessRequest = async (accessRequestId: number) => {
    setUpdatingAccessRequest(true)

    await api.updateAccessRequest(
      accessRequestId,
      UpdateAccessRequestStatus.DENIED
    )

    setShowSuccessMessage(true)
    setUpdatingAccessRequest(false)
    loadAccessRequests()
  }

  return (
    <Container fluid className={hospitalsCss.container}>
      <h3 className={hospitalsCss.title}>
        Solicitações de acesso aos repasses
      </h3>

      {!accessRequests.length && (
        <Alert variant="primary">Nenhuma solicitação pendente.</Alert>
      )}

      {showSuccessMessage && (
        <Alert
          variant="success"
          onClose={() => setShowSuccessMessage(false)}
          dismissible
        >
          Solicitação aprovada com sucesso!
        </Alert>
      )}

      <Table striped hover responsive="sm">
        <thead>
          <tr>
            <th>Médico</th>
            <th>Acesso</th>
            <th className={hospitalsCss.tableActions}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {accessRequests.map((accessRequest, index) => (
            <tr key={index}>
              <td>{accessRequest.doctorName}</td>
              <td>
                <Badge bg={AccessRequestStatusLabel[accessRequest.status]}>
                  {AccessRequestStatus[accessRequest.status]}
                </Badge>
              </td>
              <td>
                <Button
                  className={hospitalsCss.accessRequestButton}
                  variant="outline-primary"
                  size="sm"
                  onClick={() => approveAccessRequest(accessRequest.id)}
                >
                  {updatingAccessRequest && (
                    <div>
                      <Spinner animation="border" size="sm" /> Aprovar acesso
                    </div>
                  )}
                  {!updatingAccessRequest && <div>Aprovar acesso</div>}
                </Button>

                <Button
                  className={hospitalsCss.accessRequestButton}
                  variant="outline-danger"
                  size="sm"
                  onClick={() => denyAccessRequest(accessRequest.id)}
                >
                  {updatingAccessRequest && (
                    <div>
                      <Spinner animation="border" size="sm" /> Negar acesso
                    </div>
                  )}
                  {!updatingAccessRequest && <div>Negar acesso</div>}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default AccessRequestPage
