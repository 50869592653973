import { FunctionComponent, useEffect, useState } from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
} from 'react-bootstrap'
import classnames from 'classnames'

import HeaderOutside from 'components/layout/headerOutside'
import Footer from '../../../components/layout/footer'
import createPasswordCss from './createPassword.module.scss'
import * as api from '../api.client'
import { AppMessage } from '../../../utilities/messages'
import { useNavigate, useParams } from 'react-router-dom'
import { PasswordResetInfo } from '../types'

const CreatePassword: FunctionComponent = () => {
  const navigate = useNavigate()
  const { token } = useParams<{ token: string }>()
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })
  const [creatingPassword, setCreatingPassword] = useState(false)
  const [updatedCredentials, setUpdatedCredentials] =
    useState<PasswordResetInfo>({
      token: token as string,
      password: '',
      passwordConfirmation: '',
    })

  useEffect(() => {
    if (!token) {
      setAppMessage({
        show: true,
        success: false,
        message:
          'O token necessário para redefinir a senha não foi encontrado.',
      })
    }
  }, [token])

  const createPassword = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formIsValid = event.currentTarget.checkValidity()

    if (!formIsValid) {
      setShowFormErrors(true)
      return
    }

    setCreatingPassword(true)

    try {
      const { email } = await api.createPassword(updatedCredentials)
      navigate('/login', {
        state: { message: 'Senha criada com sucesso.', email },
      })
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: (error as any).messages.join(', '),
      })
    } finally {
      setCreatingPassword(false)
    }
  }

  const handleFormChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target

    setUpdatedCredentials({ ...updatedCredentials, [name]: value })
  }

  return (
    <div>
      <HeaderOutside></HeaderOutside>

      <Container fluid className={createPasswordCss.container}>
        <Row
          className={classnames(
            'justify-content-md-center',
            createPasswordCss.row
          )}
        >
          <Col md="4" className={createPasswordCss.col}>
            <Card className="mb-2">
              <Card.Body>
                <h5 className={createPasswordCss.title}>Criar senha</h5>

                {appMessage.show && (
                  <Alert
                    variant={appMessage.success ? 'success' : 'danger'}
                    style={{ marginTop: 20 }}
                  >
                    {appMessage.message}
                  </Alert>
                )}

                <Form
                  noValidate
                  validated={showFormErrors}
                  onSubmit={createPassword}
                >
                  <Form.Group className={createPasswordCss.formGroup}>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      className={createPasswordCss.formControl}
                      name="password"
                      value={updatedCredentials.password}
                      onChange={handleFormChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Informe uma senha.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Confirme sua senha</Form.Label>
                    <Form.Control
                      type="password"
                      className={createPasswordCss.formControl}
                      name="passwordConfirmation"
                      value={updatedCredentials.passwordConfirmation}
                      onChange={handleFormChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Confirme sua senha.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className={createPasswordCss.button}
                    disabled={!token}
                  >
                    {creatingPassword && (
                      <div>
                        <Spinner size="sm" animation="border" variant="dark" />{' '}
                        Processando...
                      </div>
                    )}

                    {!creatingPassword && <div>Criar senha</div>}
                  </Button>

                  <Row>
                    <Col sm="12" className={createPasswordCss.backToLogin}>
                      <a
                        href="/login"
                        className={createPasswordCss.backToLogin}
                      >
                        Voltar para o login
                      </a>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  )
}

export default CreatePassword
