import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router'
import './App.css'

import PublicRoutes from 'public-routes'
import Layout from 'components/layout/layout'
import { isAuthenticated, logout } from './features/user/auth'

const App: FunctionComponent = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated())

  const handleSuccessfulLogin = () => {
    setIsLoggedIn(isAuthenticated())
    navigate('/')
  }

  const handleLogout = () => {
    logout()
    setIsLoggedIn(isAuthenticated())
    navigate('/login?logout=true')
  }

  return isLoggedIn ? (
    <Layout handleLogout={handleLogout} />
  ) : (
    <PublicRoutes handleSuccessfulLogin={handleSuccessfulLogin} />
  )
}

export default App
