import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
} from 'react-bootstrap'
import classnames from 'classnames'

import HeaderOutside from 'components/layout/headerOutside'
import Footer from '../../../components/layout/footer'
import registerCss from './styles.module.scss'

import { isValidEmail } from 'utilities/email.util'
import * as userApi from '../api.client'
import { NewUser } from './types'

const passwordMinLength = 8

const NewUserInitialState = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
}

const SignUp: FunctionComponent = () => {
  const navigate = useNavigate()
  const [newUser, setNewUser] = useState<NewUser>(NewUserInitialState)
  const [nameSet, setNameSet] = useState(false)
  const [emailSet, setEmailSet] = useState(false)
  const [passwordSet, setPasswordSet] = useState(false)
  const [passwordConfirmationSet, setPasswordConfirmationSet] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [passwordHasMinLength, setPasswordHasMinLength] = useState(false)
  const [signingUp, setSigningUp] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const signUp = async () => {
    if (!validateUserData()) {
      return
    }

    try {
      setSigningUp(true)
      await userApi.register(newUser)
      navigate('/login')
    } catch (error: any) {
      setSigningUp(false)
      setErrorMessage(error.messages.join(', '))
    }
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (newUser) {
      setNewUser({ ...newUser, [name]: value })
    }
  }

  const validateEmail = () => {
    if (newUser) {
      const emailSet = newUser.email.trim().length > 0
      setEmailSet(emailSet)
      setValidEmail(isValidEmail(newUser.email))
    }
  }

  const validateUserData = (): boolean => {
    const nameSet = newUser.name.trim().length > 0
    const emailSet = newUser.email.trim().length > 0
    const passwordSet = newUser.password.trim().length > 0
    const passwordConfirmationSet =
      newUser.passwordConfirmation.trim().length > 0
    const passwordsMatch =
      passwordSet &&
      passwordConfirmationSet &&
      newUser.password === newUser.passwordConfirmation
    const passwordHasMinLength =
      newUser.password.trim().length >= passwordMinLength

    setSubmitted(true)
    setNameSet(nameSet)
    setPasswordSet(passwordSet)
    setPasswordConfirmationSet(passwordConfirmationSet)
    setPasswordsMatch(passwordsMatch)
    setPasswordHasMinLength(passwordHasMinLength)

    return (
      nameSet &&
      emailSet &&
      validEmail &&
      passwordSet &&
      passwordConfirmationSet &&
      passwordsMatch &&
      passwordHasMinLength
    )
  }

  return (
    <div>
      <HeaderOutside></HeaderOutside>

      <Container fluid className={registerCss.container}>
        <Row
          className={classnames('justify-content-md-center', registerCss.row)}
        >
          <Col md="4" className={registerCss.col}>
            <Card className="mb-2">
              <Card.Body>
                <h5 className={registerCss.title}>Crie uma nova conta</h5>

                <div className={registerCss.subTitle}>
                  <span>Bem-vindo ao Clic-repasse</span>
                </div>

                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                <Form>
                  <Form.Group className={registerCss.formGroup}>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      className={registerCss.formControl}
                      onChange={handleFormChange}
                    />

                    {submitted && !nameSet && (
                      <span className={registerCss.inputError}>
                        Informe seu nome
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group className={registerCss.formGroup}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      className={registerCss.formControl}
                      onChange={handleFormChange}
                      onBlur={validateEmail}
                    />

                    {submitted && !emailSet && (
                      <span className={registerCss.inputError}>
                        Informe seu email
                      </span>
                    )}

                    {!validEmail && emailSet && (
                      <span className={registerCss.inputError}>
                        Informe um email válido
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group className={registerCss.formGroup}>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      minLength={passwordMinLength}
                      className={registerCss.formControl}
                      onChange={handleFormChange}
                    />

                    {submitted && !passwordSet && (
                      <span className={registerCss.inputError}>
                        Crie uma senha com no mínimo 8 caracteres
                      </span>
                    )}

                    {submitted && !passwordHasMinLength && (
                      <span className={registerCss.inputError}>
                        A senha deve conter no mínimo 8 caracteres
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Confirmar Senha</Form.Label>
                    <Form.Control
                      type="password"
                      name="passwordConfirmation"
                      minLength={passwordMinLength}
                      className={registerCss.formControl}
                      onChange={handleFormChange}
                    />

                    {submitted && !passwordConfirmationSet && (
                      <span className={registerCss.inputError}>
                        Confirme a senha
                      </span>
                    )}

                    {submitted &&
                      passwordSet &&
                      passwordConfirmationSet &&
                      !passwordsMatch && (
                        <span className={registerCss.inputError}>
                          As senhas não conferem
                        </span>
                      )}
                  </Form.Group>

                  <Button
                    variant="primary"
                    className={registerCss.button}
                    onClick={signUp}
                  >
                    {signingUp && (
                      <div>
                        <Spinner size="sm" animation="border" variant="dark" />{' '}
                        Criando conta...
                      </div>
                    )}

                    {!signingUp && <div>Criar conta</div>}
                  </Button>

                  <Row>
                    <Col sm="12" className={registerCss.backToLogin}>
                      <a href="/login" className={registerCss.backToLogin}>
                        Voltar para o login
                      </a>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  )
}

export default SignUp
