import clientApi from '../../api/clientApi'
import { Transfer } from './types'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

export const getTransfersByHospital = async (
  hospitalId: number,
  startDate: string,
  endDate: string
): Promise<Transfer[]> => {
  const { data } = await clientApi.get(
    `${apiEndpoint}/transfers/${hospitalId}/${startDate}/${endDate}`
  )
  return data
}

export const getTransfersByDoctor = async (
  doctorId: number,
  startDate: string,
  endDate: string
): Promise<Transfer[]> => {
  const { data } = await clientApi.get(
    `${apiEndpoint}/transfers/doctor/${doctorId}/${startDate}/${endDate}`
  )
  return data
}
