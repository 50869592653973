export enum UserRole {
  ADMIN = 'ROLE_ADMIN',
  HOSPITAL_ADMIN = 'ROLE_HOSPITAL_ADMIN',
  DOCTOR = 'ROLE_DOCTOR',
}

export type CreatePasswordInfo = {
  password: string
  passwordConfirmation: string
  token: string
}

export type PasswordResetInfo = CreatePasswordInfo
