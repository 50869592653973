import {
  removeAlphabeticCharacteres,
  stringNullOrEmpty,
} from 'utilities/string.util'

export const validateCpf = (cpf: string): boolean => {
  if (stringNullOrEmpty(cpf)) return false

  cpf = removeAlphabeticCharacteres(cpf)

  var x = 0
  var soma = 0
  var dig1 = 0
  var dig2 = 0
  var len = 0
  var i = 0
  var texto = ''
  var numcpf1 = ''
  var y = ''

  len = cpf.length
  x = len - 1

  for (i = 0; i <= len - 3; i++) {
    y = cpf.substring(i, i + 1)
    soma = soma + Number(y) * x
    x = x - 1
    texto = texto + y
  }

  // % retorna o resto da divisão por 11
  dig1 = 11 - (soma % 11)

  if (dig1 === 10) dig1 = 0

  if (dig1 === 11) dig1 = 0

  numcpf1 = cpf.substring(0, len - 2) + dig1
  x = 11
  soma = 0

  for (i = 0; i <= len - 2; i++) {
    soma = soma + Number(numcpf1.substring(i, i + 1)) * Number(x)
    x = x - 1
  }
  dig2 = 11 - (soma % 11)

  if (dig2 === 10) dig2 = 0

  if (dig2 === 11) dig2 = 0

  if (dig1 + '' + dig2 === cpf.substring(len, len - 2)) {
    return true
  }

  return false
}

export const formatCpf = (strCpf?: string): string => {
  if (!strCpf) return ''

  if (stringNullOrEmpty(strCpf)) strCpf = ''

  let formattedValue = removeAlphabeticCharacteres(strCpf)

  // Split the parts of the cpf
  let part1 = formattedValue.slice(0, 3)
  let part2 = formattedValue.slice(3, 6)
  let part3 = formattedValue.slice(6, 9)
  let part4 = formattedValue.slice(9, 11)

  // Add separators: 000.000.000-00
  if (formattedValue.length > 9) {
    formattedValue = part1 + '.' + part2 + '.' + part3 + '-' + part4
  } else if (formattedValue.length > 6) {
    formattedValue = part1 + '.' + part2 + '.' + part3
  } else if (formattedValue.length > 3) {
    formattedValue = part1 + '.' + part2
  }

  return formattedValue
}
