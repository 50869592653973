import { FunctionComponent } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import classnames from 'classnames'
import headerCss from './header.module.scss'
import logo from 'assets/images/logo.png'
import { UserRole } from 'features/user/types'
import {
  getHospitalAdmin,
  getUserProfile,
  getUsername,
} from '../../features/user/auth'

/**
 * Header used when the user is logged in
 */
const Header: FunctionComponent<{ logout: () => void }> = ({ logout }) => {
  const navigate = useNavigate()

  const isUserDoctor = (): boolean => getUserProfile() === UserRole.DOCTOR

  const isUserHospitalAdmin = (): boolean =>
    getUserProfile() === UserRole.HOSPITAL_ADMIN

  const isUserAdmin = (): boolean => getUserProfile() === UserRole.ADMIN

  const openDoctorPage = () => navigate('/doctor')

  return (
    <Navbar expand="lg" className={headerCss.customNavBar}>
      <Navbar.Brand href="#">
        <img
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Clic-repasse"
        />{' '}
        Clic-repasse{' '}
        {isUserHospitalAdmin() && (
          <b style={{ fontSize: 12, fontStyle: 'italic' }}>
            {getHospitalAdmin()}
          </b>
        )}
      </Navbar.Brand>

      <Nav className={classnames('mr-auto', headerCss.customNav)}>
        {(isUserDoctor() || isUserHospitalAdmin()) && (
          <Link
            className={classnames('nav-link', headerCss.customNavLink)}
            to="/transfers"
          >
            Repasses
          </Link>
        )}
        {(isUserDoctor() || isUserAdmin()) && (
          <Link
            className={classnames('nav-link', headerCss.customNavLink)}
            to="/hospitals"
          >
            Hospitais
          </Link>
        )}

        {isUserAdmin() && (
          <>
            <Link
              className={classnames('nav-link', headerCss.customNavLink)}
              to="/doctors"
            >
              Médicos
            </Link>

            <Link
              className={classnames('nav-link', headerCss.customNavLink)}
              to="/users"
            >
              Usuários
            </Link>
          </>
        )}

        {isUserHospitalAdmin() && (
          <Link
            className={classnames('nav-link', headerCss.customNavLink)}
            to="/access-requests"
          >
            Solicitações de acesso
          </Link>
        )}
        {isUserHospitalAdmin() && (
          <Link
            className={classnames('nav-link', headerCss.customNavLink)}
            to="/doctors"
          >
            Médicos
          </Link>
        )}
      </Nav>
      <Navbar.Collapse id="nva-user-name" className="justify-content-end">
        <Nav>
          <NavDropdown id="user-name" title={getUsername()} align={'end'}>
            <NavDropdown.Item href="#">Minha conta</NavDropdown.Item>
            {isUserDoctor() && (
              <NavDropdown.Item href="#" onClick={openDoctorPage}>
                Meus dados
              </NavDropdown.Item>
            )}
            <NavDropdown.Divider />
            <NavDropdown.Item href="#" onClick={logout}>
              Sair
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
