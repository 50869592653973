import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  Container,
  Button,
  Row,
  Form,
  Breadcrumb,
  Spinner,
  Table,
  Alert,
} from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'

import * as api from './api.client'
import hospitalsCss from './style.module.scss'
import { AdminHospital } from './types'
import { formatCNPJ } from '../../utilities/cnpj.util'
import {
  removeAlphabeticCharacteres,
  stringNullOrEmpty,
} from '../../utilities/string.util'
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { AppMessage } from '../../utilities/messages'
import ConfirmationModal from '../../components/modal/confirmationModal'

const newHospitalAdminState = { name: '', email: '' }

const EditHospital: FunctionComponent = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [hospitalId, setHospitalId] = useState<number>()
  const [hospital, setHospital] = useState<AdminHospital>()
  const [newHospitalAdmin, setHospitalAdmin] = useState(newHospitalAdminState)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [removingHospitalAdminlId, setRemovingHospitalAdminlId] =
    useState<number>()
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [saving, setSaving] = useState(false)
  const [creatingHospitalAdmin, setCreatingHospitalAdmin] = useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })

  useEffect(() => {
    getHospitalById(Number.parseInt(id as string))
  }, [id])

  const getHospitalById = async (id: number) => {
    const hospital = await api.getHospitalById(id)
    setHospital(hospital)
    setHospitalId(id)
  }

  if (!hospital || !hospitalId) {
    return null
  }

  const updateHospital = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formIsValid = event.currentTarget.checkValidity()

    if (!formIsValid) {
      setShowFormErrors(true)
      return
    }

    setSaving(true)

    try {
      await api.updateHospital(hospital.id, {
        ...hospital,
        cnpj: removeAlphabeticCharacteres(hospital.cnpj),
      })
      setAppMessage({ show: false })
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: 'Erro ao atualizar os dados do hospital.',
      })
    } finally {
      setSaving(false)
    }
  }

  const addHospitalAdmin = async () => {
    try {
      setCreatingHospitalAdmin(true)

      await api.addHospitalAdmin({
        ...newHospitalAdmin,
        hospitalId,
      })
      await getHospitalById(hospitalId)

      setHospitalAdmin(newHospitalAdminState)
      setAppMessage({
        show: true,
        success: true,
        message:
          'Admin adicionado com sucesso. Enviamos um email para o usuário solicitando a confirmação do cadastro.',
      })
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: `Erro ao adicionar o administrador do hospital: ${(
          error as any
        ).messages.join(', ')}`,
      })
    } finally {
      setCreatingHospitalAdmin(false)
    }
  }

  const removeHospitalAdmin = async () => {
    if (!removingHospitalAdminlId) {
      return
    }

    try {
      await api.removeHospitalAdmin(removingHospitalAdminlId)
      await getHospitalById(hospitalId)
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: 'Erro ao remover o admin.',
      })
    } finally {
      setShowRemoveConfirmation(false)
      setRemovingHospitalAdminlId(undefined)
    }
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target

    setHospital({
      ...hospital,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleFormChangeHospitalAdmin = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setHospitalAdmin({ ...newHospitalAdmin, [name]: value })
  }

  const isValidHospitalAdmin = () =>
    !stringNullOrEmpty(newHospitalAdmin.name) &&
    !stringNullOrEmpty(newHospitalAdmin.email)

  return (
    <Container fluid className={hospitalsCss.container}>
      {appMessage.show && (
        <Alert
          variant={appMessage.success ? 'success' : 'danger'}
          style={{ marginTop: 20 }}
          onClose={() => setAppMessage({ show: false })}
          dismissible
        >
          {appMessage.message}
        </Alert>
      )}

      {showRemoveConfirmation && (
        <ConfirmationModal
          message="Você deseja realmente remover o admin?"
          btnMessage="Remover"
          btnVariant="outline-danger"
          onClose={() => setShowRemoveConfirmation(false)}
          onConfirmation={() => removeHospitalAdmin()}
        />
      )}

      <Row>
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => navigate('/hospitals')}>
            Hospitais
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit hospital</Breadcrumb.Item>
          <Breadcrumb.Item active>{hospital.name}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Form noValidate validated={showFormErrors} onSubmit={updateHospital}>
        <Form.Group className="mb-3" controlId="formNome">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nome"
            name="name"
            value={hospital.name}
            onChange={handleFormChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe o nome do hospital.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCNPJ">
          <Form.Label>CNPJ</Form.Label>
          <Form.Control
            type="text"
            placeholder="CNPJ"
            name="cnpj"
            value={formatCNPJ(hospital.cnpj)}
            onChange={handleFormChange}
            minLength={18}
            maxLength={18}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe um CNPJ válido.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formHospitalApiUrl">
          <Form.Label>URL API Hospital</Form.Label>
          <Form.Control
            type="text"
            placeholder="URL API Hospital"
            name="hospitalApiUrl"
            value={hospital.hospitalApiUrl}
            onChange={handleFormChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe a URL da API do hospital.
          </Form.Control.Feedback>
          <Form.Text className="text-muted">
            Endereço da API que é instalada no infraestrutura do hospital, para
            integrar os repasses com a API do repasse médico.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formHospitalAtivo">
          <Form.Check
            type="switch"
            label="Hospital ativo"
            name="enabled"
            checked={hospital.enabled}
            onChange={handleFormChange}
          />
          <Form.Text className="text-muted">
            Só habilite quando a integração com o hospital estiver validada pela
            equipe de desenvolvimento.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          {saving && (
            <div>
              <SaveIcon />{' '}
              <span className={hospitalsCss.txtButtonAddHospital}>Salvar</span>{' '}
              <Spinner animation="border" size="sm" />{' '}
            </div>
          )}
          {!saving && (
            <div>
              <SaveIcon />{' '}
              <span className={hospitalsCss.txtButtonAddHospital}>Salvar</span>
            </div>
          )}
        </Button>
      </Form>

      <div className={hospitalsCss.hospitalAdmins}>
        <h5>Admins</h5>
        <Form.Text className="text-muted">
          Os admins são funcionários do hospital com permissão para adicionar
          novos médicos e controlar o acesso dos médicos aos repasses.
        </Form.Text>

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {hospital.hospitalAdmins.map((admin, index) => (
              <tr key={index}>
                <td>{admin.name}</td>
                <td>{admin.email}</td>
                <td>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => {
                      setShowRemoveConfirmation(true)
                      setRemovingHospitalAdminlId(admin.id)
                    }}
                  >
                    <Trash size={16} />
                  </Button>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <Form.Control
                  type="text"
                  name="name"
                  value={newHospitalAdmin?.name}
                  onChange={handleFormChangeHospitalAdmin}
                  required
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="email"
                  value={newHospitalAdmin?.email}
                  onChange={handleFormChangeHospitalAdmin}
                  required
                />
              </td>
              <td>
                <Button
                  className={hospitalsCss.addHospitalAdmin}
                  variant="outline-primary"
                  size="sm"
                  disabled={!isValidHospitalAdmin()}
                  onClick={() => addHospitalAdmin()}
                >
                  {!creatingHospitalAdmin && <AddIcon />}

                  {creatingHospitalAdmin && (
                    <Spinner animation="border" size="sm" />
                  )}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Container>
  )
}

export default EditHospital
