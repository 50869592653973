import axios from 'axios'

import {
  getToken,
  removeToken,
  isAuthenticated,
  isTokenExpired,
} from '../features/user/auth'

export type ApiError = {
  code: number

  messages: string[]
}

const api = axios.create()

const appUrl = 'https://repassemedico.com.br'

api.interceptors.request.use(async (config) => {
  const token = getToken()

  handleExpiredSession()

  if (token && !isTokenExpired()) {
    config.headers.Authorization = token
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    handleExpiredSession()

    const { response } = error

    if (!response.data.code) {
      response.data = {
        code: response.status,
        message: 'Erro ao realizar a operação.',
      }
    }

    return Promise.reject(response.data)
  }
)

const handleExpiredSession = () => {
  if (isAuthenticated() && isTokenExpired()) {
    redirectToLogin()
  }
}

const redirectToLogin = () => {
  removeToken()
  window.location.href = appUrl + '/login?sessionExpired=true'
}

export default api
