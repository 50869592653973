import { FunctionComponent } from 'react'
import Header from './header'
import Footer from './footer'
import PrivateRoutes from 'private-routes'

const Layout: FunctionComponent<{ handleLogout: () => void }> = ({
  handleLogout,
}) => (
  <main style={{ backgroundColor: '#fbfbfb' }}>
    <Header logout={handleLogout} />
    <PrivateRoutes />
    <Footer signedIn={true} />
  </main>
)

export default Layout
