import { removeAlphabeticCharacteres } from './string.util'

export const formatCNPJ = (cnpj: string) => {
  // Removes everything that's not a number
  if (cnpj) {
    cnpj = removeAlphabeticCharacteres(cnpj)
  }

  if (!cnpj || cnpj.length < 3) {
    return cnpj
  }

  if (cnpj.length < 6) {
    return `${cnpj.substring(0, 2)}.${cnpj.substring(2, cnpj.length)}`
  }

  if (cnpj.length < 9) {
    return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(
      5,
      cnpj.length
    )}`
  }

  if (cnpj.length < 13) {
    return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(
      5,
      8
    )}/${cnpj.substring(8, cnpj.length)}`
  }

  return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(
    5,
    8
  )}/${cnpj.substring(8, 12)}-${cnpj.substring(12, cnpj.length)}`
}
