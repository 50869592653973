import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
} from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import classnames from 'classnames'

import HeaderOutside from 'components/layout/headerOutside'
import Footer from '../../../components/layout/footer'
import loginCss from './styles.module.scss'
import clientApi from 'api/clientApi'
import {
  setHospitalAdmin,
  setToken,
  setUsername,
  setUserProfile,
} from '../auth'
import { isValidEmail } from 'utilities/email.util'
import * as userApi from '../api.client'
import * as hospitalAdminApi from '../../adminHospital/api.client'
import { AppMessage } from '../../../utilities/messages'
import { UserRole } from '../types'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

export const SignIn: FunctionComponent<{
  handleSuccessfulLogin: () => void
}> = ({ handleSuccessfulLogin }) => {
  const location = useLocation()
  const { sessionExpired } = useParams<{ sessionExpired?: string }>()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [emailSet, setEmailSet] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [passwordSet, setPasswordSet] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [tokenExpired, setTokenExpired] = useState(false)
  const [entering, setEntering] = useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })

  const showStateMessage = useCallback(() => {
    if (location.state && location.state.message) {
      setAppMessage({
        show: true,
        success: true,
        message: location.state.message,
      })
    }

    if (location.state && location.state.email) {
      setEmail(location.state.email)
      setValidEmail(isValidEmail(location.state.email))
    }
  }, [location.state])

  useEffect(() => {
    setTokenExpired(!!sessionExpired)
    showStateMessage()
  }, [sessionExpired, showStateMessage])

  const login = async () => {
    if (!preValidateCredentials()) {
      return
    }

    try {
      setEntering(true)

      const { headers } = await clientApi.post(`/${apiEndpoint}/login`, {
        username: email,
        password: password,
      })

      successfulLogin(headers.authorization)
    } catch (error) {
      console.error(error)
      setLoginError(true)
      setEntering(false)
    }
  }

  const handleLoginKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      login()
    }
  }

  const successfulLogin = async (token: string) => {
    setLoginError(false)
    setEntering(false)
    setToken(token)

    try {
      const user = await userApi.get()

      if (user.role === UserRole.HOSPITAL_ADMIN) {
        const hospitalAdmin = await hospitalAdminApi.getHospitalAdmin()
        setHospitalAdmin(hospitalAdmin.hospital)
      }

      setUserProfile(user.role)
      setUsername(user.name)

      handleSuccessfulLogin()
    } catch (error) {
      console.error(error)
    }
  }

  const preValidateCredentials = (): boolean => {
    const emailSet = !!email && email.trim().length > 0
    const passwordSet = !!password && password.trim().length > 0

    setSubmitted(true)
    setEmailSet(!!email)
    setPasswordSet(!!password)

    return emailSet && validEmail && passwordSet
  }

  const validateEmail = () => {
    if (email) {
      setEmailSet(true)
      setValidEmail(isValidEmail(email))
    }
  }

  return (
    <div>
      <HeaderOutside></HeaderOutside>

      <Container fluid className={loginCss.container}>
        {tokenExpired && (
          <Alert variant="danger" style={{ marginTop: 20 }}>
            Sessão expirada, faça o login novamente.
          </Alert>
        )}

        <Row className={classnames('justify-content-md-center', loginCss.row)}>
          <Col md="3" className={loginCss.col}>
            <Card className={classnames('mb-2', loginCss.card)}>
              <Card.Body>
                <h5 className={loginCss.title}>Acessar o Clic-repasse</h5>

                {loginError && (
                  <Alert variant="danger">Email ou senha incorreto.</Alert>
                )}

                {appMessage.show && (
                  <Alert
                    variant={appMessage.success ? 'success' : 'danger'}
                    style={{ marginTop: 20 }}
                  >
                    {appMessage.message}
                  </Alert>
                )}

                <Form>
                  <div style={{ marginBottom: '1rem' }}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      className={loginCss.formControl}
                      onChange={(event) => setEmail(event.target.value)}
                      onBlur={validateEmail}
                    />

                    {submitted && !emailSet && (
                      <span className={loginCss.invalidCredentials}>
                        Informe seu email
                      </span>
                    )}

                    {!validEmail && emailSet && (
                      <span className={loginCss.invalidCredentials}>
                        Informe um email válido
                      </span>
                    )}
                  </div>

                  <div>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      className={loginCss.formControl}
                      onChange={(event) => setPassword(event.target.value)}
                      onKeyPress={handleLoginKeyUp}
                    />
                    {submitted && !passwordSet && (
                      <span className={loginCss.invalidCredentials}>
                        Informe sua senha
                      </span>
                    )}
                  </div>

                  <Button className={loginCss.button} onClick={login}>
                    {entering && (
                      <div>
                        <Spinner size="sm" animation="border" variant="dark" />{' '}
                        Entrando...
                      </div>
                    )}

                    {!entering && <div>Entrar</div>}
                  </Button>

                  <Row>
                    <Col sm="6" className={loginCss.options}>
                      <a
                        href="password-recovery"
                        className={loginCss.forgotPassword}
                      >
                        Esqueceu a senha?
                      </a>
                    </Col>

                    <Col
                      sm="6"
                      className={classnames(
                        loginCss.register,
                        loginCss.options
                      )}
                    >
                      <a href="/register" className={loginCss.register}>
                        Realizar cadastro
                      </a>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  )
}

export default SignIn
