import { AxiosResponse } from 'axios'
import clientApi from '../../api/clientApi'
import {
  AdminHospital,
  HospitalAdmin,
  NewHospital,
  NewHospitalAdmin,
  UpdatedHospital,
} from './types'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

export const getHospitals = async (): Promise<AdminHospital[]> => {
  const { data } = await clientApi.get<AdminHospital[]>(
    `${apiEndpoint}/hospitals`
  )

  return data
}

export const getHospitalById = async (id: number): Promise<AdminHospital> => {
  const { data } = await clientApi.get<AdminHospital>(
    `${apiEndpoint}/hospitals/${id}`
  )

  return data
}

export const addHospital = async (
  hospital: NewHospital
): Promise<AdminHospital> => {
  const { data } = await clientApi.post<
    NewHospital,
    AxiosResponse<AdminHospital>
  >(`${apiEndpoint}/hospitals`, hospital)

  return data
}

export const updateHospital = async (
  id: number,
  hospital: UpdatedHospital
): Promise<AdminHospital> =>
  clientApi.put<UpdatedHospital, AdminHospital>(
    `${apiEndpoint}/hospitals/${id}`,
    hospital
  )

export const removeHospital = async (id: number): Promise<void> =>
  clientApi.delete(`${apiEndpoint}/hospitals/${id}`)

export const addHospitalAdmin = async (
  admin: NewHospitalAdmin
): Promise<HospitalAdmin> =>
  clientApi.post<NewHospitalAdmin, HospitalAdmin>(
    `${apiEndpoint}/hospitals/admins`,
    admin
  )

export const removeHospitalAdmin = async (id: number): Promise<void> =>
  clientApi.delete(`${apiEndpoint}/hospitals/admins/${id}`)
