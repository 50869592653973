import { AxiosResponse } from 'axios'
import clientApi from '../../api/clientApi'
import { NewUser, User } from './signUp/types'
import { CreatePasswordInfo, PasswordResetInfo } from './types'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

export const register = (newUser: NewUser) => {
  return clientApi.post(`/${apiEndpoint}/users`, newUser)
}

export const get = async (): Promise<User> => {
  const { data } = await clientApi.get(`/${apiEndpoint}/users/me`)
  return data
}

export const createPassword = async (
  createPasswordInfo: CreatePasswordInfo
): Promise<User> => {
  const { data } = await clientApi.patch<
    CreatePasswordInfo,
    AxiosResponse<User>
  >(`/${apiEndpoint}/users/password`, createPasswordInfo)
  return data
}

export const recoverPassword = (email: string) => {
  return clientApi.patch(`/${apiEndpoint}/users/recover-password`, { email })
}

export const resetPassword = (passwordResetInfo: PasswordResetInfo) => {
  return clientApi.patch(
    `/${apiEndpoint}/users/reset-password`,
    passwordResetInfo
  )
}
