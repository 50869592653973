import {
  AccessRequest,
  UpdateAccessRequestStatus,
} from 'features/accessRequest/types'
import clientApi from '../../api/clientApi'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

/**
 * Retorna as solicitações de acesso vinculadas ao hospital do admin
 */
export const getAccessRequests = async (): Promise<AccessRequest[]> => {
  const { data } = await clientApi.get<AccessRequest[]>(
    `${apiEndpoint}/access-requests`
  )

  return data
}

export const requestAccess = (hospitalId: number) => {
  return clientApi.post(
    `${apiEndpoint}/access-requests?hospitalId=${hospitalId}`
  )
}

export const updateAccessRequest = (
  id: number,
  status: UpdateAccessRequestStatus
) => {
  return clientApi.patch(`${apiEndpoint}/access-requests/${id}`, {
    status,
  })
}

export const revokeAccessRequestByDoctor = (doctorId: number) => {
  return clientApi.patch(`${apiEndpoint}/access-requests/revoke`, {
    doctorId,
  })
}
