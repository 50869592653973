import { Routes, Route, Navigate } from 'react-router-dom'

import SignIn from 'features/user/signIn/signIn'
import Register from 'features/user/signUp/signUp'
import PasswordRecovery from './features/user/resetPassword/passwordRecovery'
import PasswordReset from 'features/user/resetPassword/passwordReset'
import { isAuthenticated } from './features/user/auth'
import CreatePassword from './features/user/createPassword/createPassword'

const PublicRoute = ({ children }) => {
  if (isAuthenticated()) {
    return <Navigate to="transfers" replace />
  }

  return children
}

const PublicRoutes = (props) => {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <PublicRoute>
            <SignIn handleSuccessfulLogin={props.handleSuccessfulLogin} />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="register"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="password-recovery"
        element={
          <PublicRoute>
            <PasswordRecovery />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="password-reset/:token"
        element={
          <PublicRoute>
            <PasswordReset />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="create-password/:token"
        element={
          <PublicRoute>
            <CreatePassword />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="/"
        element={
          <PublicRoute>
            <SignIn handleSuccessfulLogin={props.handleSuccessfulLogin} />
          </PublicRoute>
        }
      ></Route>
    </Routes>
  )
}

export default PublicRoutes
