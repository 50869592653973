import {
  getMonthNameAndYear,
  getMonthAndYearAsNumberFromString,
  getDateFromString,
  diffBetweenDates,
} from 'utilities/date.util'
import { Transfer, TransferStatus } from './types'

export class TransferIndicators {
  constructor() {
    this.totalTransferValueByStatus = new Map()

    this.paidTransfersGroupedByMonthAndYear = new Map()
    this.releasedTransfersGroupedByMonthAndYear = new Map()
    this.processingTransfersGroupedByMonthAndYear = new Map()
    this.pendingTransfersGroupedByMonthAndYear = new Map()
    this.disapprovedTransfersGroupedByMonthAndYear = new Map()
    this.totalAttendances = 0
    this.averageReceiptPeriod = 0
    this.averageRevenueTicket = 0
  }

  totalTransferValueByStatus: Map<TransferStatus, number>

  paidTransfersGroupedByMonthAndYear: Map<number, Transfer[]>
  releasedTransfersGroupedByMonthAndYear: Map<number, Transfer[]>
  processingTransfersGroupedByMonthAndYear: Map<number, Transfer[]>
  pendingTransfersGroupedByMonthAndYear: Map<number, Transfer[]>
  disapprovedTransfersGroupedByMonthAndYear: Map<number, Transfer[]>

  totalAttendances: number
  averageReceiptPeriod: number
  averageRevenueTicket: number

  getTotalByStatus(transferStatus: TransferStatus): number {
    let totalByStatus = this.totalTransferValueByStatus.get(transferStatus)

    if (totalByStatus !== undefined) {
      return totalByStatus
    }

    return 0
  }

  // Valores dos repasses totalizados por mês/ano
  getTotalGroupedByMonthAndYearFrom(
    transferStatus: TransferStatus
  ): Map<number, number> {
    switch (transferStatus) {
      case TransferStatus.PAGO:
        return this.getTotalGroupedByMonthAndYear(
          this.paidTransfersGroupedByMonthAndYear
        )
      case TransferStatus.LIBERADO:
        return this.getTotalGroupedByMonthAndYear(
          this.releasedTransfersGroupedByMonthAndYear
        )
      case TransferStatus.PROCESSANDO:
        return this.getTotalGroupedByMonthAndYear(
          this.processingTransfersGroupedByMonthAndYear
        )
      case TransferStatus.PENDENTE:
        return this.getTotalGroupedByMonthAndYear(
          this.pendingTransfersGroupedByMonthAndYear
        )
      case TransferStatus.GLOSADO:
        return this.getTotalGroupedByMonthAndYear(
          this.disapprovedTransfersGroupedByMonthAndYear
        )
    }
  }

  private getTotalGroupedByMonthAndYear(
    transfersGroupedByMonthAndYear: Map<number, Array<Transfer>>
  ): Map<number, number> {
    var totalValueGroupedByMonthAndYear = new Map()

    let yearAndMonthKeys = Array.from(
      transfersGroupedByMonthAndYear.keys()
    ).sort((a, b) => a - b)

    yearAndMonthKeys.forEach((yearAndMonth) => {
      let totalByMonth = transfersGroupedByMonthAndYear
        .get(yearAndMonth)
        ?.map((transfer) => transfer.valorRepasse)
        .reduce((valor01, valor02) => valor01 + valor02)

      totalValueGroupedByMonthAndYear.set(
        getMonthNameAndYear(yearAndMonth),
        totalByMonth
      )
    })

    return totalValueGroupedByMonthAndYear
  }

  private addTransferGroupingByMonth(
    groupedTransfers: Map<number, Array<Transfer>>,
    transfer: Transfer
  ) {
    let transferPeriod = getMonthAndYearAsNumberFromString(
      transfer.dataProtocolo
    )

    if (
      transfer.status === TransferStatus.PAGO &&
      transfer.dataFechamentoRepasse != null
    ) {
      transferPeriod = getMonthAndYearAsNumberFromString(
        transfer.dataFechamentoRepasse
      )
    }

    if (!groupedTransfers.has(transferPeriod)) {
      let transfers = new Array<Transfer>()
      groupedTransfers.set(transferPeriod, transfers)
    }

    groupedTransfers.get(transferPeriod)?.push(transfer)
  }

  private calculateTotalAttendances(transfers: Array<Transfer>): number {
    var atendimentos = new Set()

    transfers
      .filter((t) => t.numeroAtendimento != null)
      .forEach((transfer) => {
        atendimentos.add(transfer.numeroAtendimento)
      })

    return atendimentos.size
  }

  private calculateAverageReceiptPeriod(transfers: Array<Transfer>): number {
    var totalAttendances = transfers.filter(
      (t) => t.status === TransferStatus.PAGO
    ).length

    var total = transfers
      .filter((t) => t.status === TransferStatus.PAGO)
      .map((transfer) =>
        diffBetweenDates(
          getDateFromString(transfer.dataProcedimento),
          getDateFromString(transfer.dataFechamentoRepasse)
        )
      )
      .reduce((totalDiff, diffInDays) => totalDiff + diffInDays, 0)

    return Math.round(total / totalAttendances)
  }

  private calculateAverageRevenueTicket(transfers: Array<Transfer>): number {
    var total = transfers
      .filter((t) => t.numeroAtendimento != null && t.valorRepasse != null)
      .reduce((t, transfer) => t + transfer.valorRepasse, 0)

    return total / this.totalAttendances
  }

  static build(transfers: Array<Transfer>, convenio: string) {
    let indicators = new TransferIndicators()

    let totalPaidValue = 0
    let totalReleasedValue = 0
    let totalProcessingValue = 0
    let totalPendingValue = 0
    let totalGlossedValue = 0

    for (let transfer of transfers) {
      if (convenio !== 'All' && convenio !== transfer.convenio) {
        continue
      }

      switch (transfer.status) {
        case TransferStatus.PAGO:
          totalPaidValue += transfer.valorRepasse
          indicators.addTransferGroupingByMonth(
            indicators.paidTransfersGroupedByMonthAndYear,
            transfer
          )
          break
        case TransferStatus.LIBERADO:
          totalReleasedValue += transfer.valorRepasse
          indicators.addTransferGroupingByMonth(
            indicators.releasedTransfersGroupedByMonthAndYear,
            transfer
          )
          break
        case TransferStatus.PROCESSANDO:
          totalProcessingValue += transfer.valorRepasse
          indicators.addTransferGroupingByMonth(
            indicators.processingTransfersGroupedByMonthAndYear,
            transfer
          )
          break
        case TransferStatus.GLOSADO:
          totalGlossedValue += transfer.valorRepasse
          indicators.addTransferGroupingByMonth(
            indicators.disapprovedTransfersGroupedByMonthAndYear,
            transfer
          )
          break
        case TransferStatus.PENDENTE:
          if (transfer.descricaoStatus === TransferStatus.GLOSADO) {
            totalGlossedValue += transfer.valorRepasse
            transfer.status = TransferStatus.GLOSADO
            indicators.addTransferGroupingByMonth(
              indicators.disapprovedTransfersGroupedByMonthAndYear,
              transfer
            )
          } else if (
            transfer.descricaoStatus === 'Liberado pelo Retorno' ||
            transfer.descricaoStatus === 'Liberado pelo sistema' ||
            (transfer.observacaoRepasse !== null &&
              transfer.numeroAtendimento === null)
          ) {
            totalProcessingValue += transfer.valorRepasse
            transfer.status = TransferStatus.PROCESSANDO
            indicators.addTransferGroupingByMonth(
              indicators.processingTransfersGroupedByMonthAndYear,
              transfer
            )
          } else {
            totalPendingValue += transfer.valorRepasse
            indicators.addTransferGroupingByMonth(
              indicators.pendingTransfersGroupedByMonthAndYear,
              transfer
            )
          }

          break
      }
    }

    indicators.totalTransferValueByStatus.set(
      TransferStatus.PAGO,
      totalPaidValue
    )
    indicators.totalTransferValueByStatus.set(
      TransferStatus.LIBERADO,
      totalReleasedValue
    )
    indicators.totalTransferValueByStatus.set(
      TransferStatus.PROCESSANDO,
      totalProcessingValue
    )
    indicators.totalTransferValueByStatus.set(
      TransferStatus.PENDENTE,
      totalPendingValue
    )
    indicators.totalTransferValueByStatus.set(
      TransferStatus.GLOSADO,
      totalGlossedValue
    )
    indicators.totalAttendances =
      indicators.calculateTotalAttendances(transfers)
    indicators.averageReceiptPeriod =
      indicators.calculateAverageReceiptPeriod(transfers)
    indicators.averageRevenueTicket =
      indicators.calculateAverageRevenueTicket(transfers)

    return indicators
  }
}
