/**
 * Build a number containing the year and the month from a date. The format date should be "dd/mm/yyyy"
 *
 * @param dateString date formatted as "dd/mm/yyyy"
 */
export const getMonthAndYearAsNumberFromString = (
  dateString: string
): number => {
  let dateParts = dateString.split('/')

  let date = new Date(
    Number.parseInt(dateParts[2]),
    Number.parseInt(dateParts[1]) - 1,
    Number.parseInt(dateParts[0])
  )

  return getMonthAndYearAsNumberFromDate(date)
}

/**
 * Build a number containing the year and the month from a date. The format date should be "dd/mm/yyyy"
 *
 * @param date Date
 */
const getMonthAndYearAsNumberFromDate = (date: Date): number => {
  let month = date.getMonth() + 1

  if (month < 10) {
    return Number.parseInt(`${date.getFullYear()}0${month}`)
  }

  return Number.parseInt(`${date.getFullYear()}${month}`)
}

/**
 * Build a date a string.
 *
 * @param dateString date formatted as "dd/mm/yyyy"
 */
export const getDateFromString = (dateString: string | null): Date => {
  if (dateString === null) return new Date()

  let dateParts = dateString.split('/')

  let date = new Date(
    Number.parseInt(dateParts[2]),
    Number.parseInt(dateParts[1]) - 1,
    Number.parseInt(dateParts[0])
  )

  return date
}

/**
 * @param yearAndMonth The year and month as one number. Ex: 01/10/2020 -> 202010
 * @returns the name of the month and the year. out/2020
 */
export const getMonthNameAndYear = (yearAndMonth: number): string => {
  let yearAndMonthStr = yearAndMonth.toString()

  let year = yearAndMonthStr.substring(0, 4)

  let month = yearAndMonthStr.substring(4, yearAndMonthStr.length)

  let date = new Date(Number.parseInt(year), Number.parseInt(month) - 1)

  return getInitialNameOfMonth(date) + '/' + year
}

export const diffBetweenDates = (startDate: Date, endDate: Date): number => {
  return (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
}

const getFullNameOfMonth = (date: Date): string => {
  return date.toLocaleString('default', { month: 'long' })
}

/**
 * @param date
 * @returns first three letters of the month name
 */
const getInitialNameOfMonth = (date: Date): string => {
  return getFullNameOfMonth(date).substring(0, 3)
}
