import { Routes, Route, Navigate } from 'react-router-dom'

import Transfers from 'features/transfers/transfers'
import Hospitals from 'features/hospitals/hospitals'
import AdminHospitals from 'features/admin/adminHospitals'

import { getUserProfile, isAuthenticated } from './features/user/auth'
import DoctorRegistration from 'features/doctors/addDoctor'
import AccessRequestPage from 'features/accessRequest/accessRequest'
import { UserRole } from './features/user/types'
import AddHospital from './features/admin/addHospital'
import EditHospital from './features/admin/editHospital'
import HospitalDoctors from './features/adminHospital/hospitalDoctors'
import AddDoctor from './features/adminHospital/addDoctor'

const PrivateRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="login" replace />
  }

  return children
}

const PrivateRoutes = () => {
  if (getUserProfile() === UserRole.ADMIN) {
    return (
      <Routes>
        <Route
          path="hospitals/new"
          element={
            <PrivateRoute>
              <AddHospital />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="hospitals/:id"
          element={
            <PrivateRoute>
              <EditHospital />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="hospitals"
          element={
            <PrivateRoute>
              <AdminHospitals />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AdminHospitals />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    )
  }

  if (getUserProfile() === UserRole.HOSPITAL_ADMIN) {
    return (
      <Routes>
        <Route
          path="transfers"
          element={
            <PrivateRoute>
              <Transfers />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="doctors/new"
          element={
            <PrivateRoute>
              <AddDoctor />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="doctors"
          element={
            <PrivateRoute>
              <HospitalDoctors />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="access-requests"
          element={
            <PrivateRoute>
              <AccessRequestPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Transfers />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route
        path="transfers"
        element={
          <PrivateRoute>
            <Transfers />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="hospitals"
        element={
          <PrivateRoute>
            <Hospitals />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="doctor"
        element={
          <PrivateRoute>
            <DoctorRegistration />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Transfers />
          </PrivateRoute>
        }
      ></Route>
    </Routes>
  )
}

export default PrivateRoutes
