import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Container,
  Table,
  Button,
  Badge,
  Spinner,
  Alert,
} from 'react-bootstrap'

import { HospitalAvailable } from 'features/hospitals/types'
import hospitalsCss from './styles.module.scss'
import * as hospitalApi from './api.client'
import * as doctorApi from '../doctors/api.client'
import * as accessRequestApi from '../accessRequest/api.client'
import {
  AccessRequestStatus,
  AccessRequestStatusLabel,
} from 'features/accessRequest/types'

const Hospitals: FunctionComponent = () => {
  const navigate = useNavigate()
  const [hospitals, setHospitals] = useState<HospitalAvailable[]>([])
  const [doctorAlreadyCreated, setDoctorAlreadyCreated] = useState(false)
  const [requestingAccess, setRequestingAccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState({ show: false, message: '' })

  useEffect(() => {
    checkIfDoctorRegisterIsCompleted()
    loadAvailableHospitals()
  }, [])

  const checkIfDoctorRegisterIsCompleted = async () => {
    try {
      const { doctorCreated } = await doctorApi.getDoctorFromLoggedUser()

      setDoctorAlreadyCreated(doctorCreated)
    } catch {}
  }

  const loadAvailableHospitals = async () => {
    try {
      const hospitals = await hospitalApi.getAvailableHospitals()
      setHospitals(hospitals)
    } catch (error) {
      setErrorMessage({
        show: true,
        message: 'Erro ao listar os hospitais disponíveis.',
      })
    }
  }

  const showRequestButton = (
    accessRequestStatus: AccessRequestStatus
  ): boolean => {
    if (!doctorAlreadyCreated) {
      return false
    }

    const statusThatShouldNotShowTheButton = [
      AccessRequestStatus.REQUESTED.toString(),
      AccessRequestStatus.APPROVED.toString(),
    ]

    return (
      statusThatShouldNotShowTheButton.findIndex(
        (s) => s === AccessRequestStatus[accessRequestStatus]
      ) === -1
    )
  }

  const requestAccess = async (hospitalId: number) => {
    try {
      setRequestingAccess(true)
      await accessRequestApi.requestAccess(hospitalId)
      setRequestingAccess(false)
      loadAvailableHospitals()
    } catch (error) {
      setErrorMessage({
        show: true,
        message: 'Erro ao solicitar acesso ao hospital.',
      })
    }
  }

  const openDoctorPage = () => {
    navigate('/doctor')
  }

  return (
    <Container fluid className={hospitalsCss.container}>
      <h3 className={hospitalsCss.title}>Hospitais</h3>

      {errorMessage.show && (
        <Alert variant="danger" style={{ marginTop: 20 }}>
          {errorMessage.message}
        </Alert>
      )}

      {!doctorAlreadyCreated && (
        <Alert variant="warning">
          Para poder solicitar o acesso aos repasses, finalize o{' '}
          <b style={{ cursor: 'pointer' }} onClick={openDoctorPage}>
            {' '}
            cadastro{' '}
          </b>{' '}
          com seu CPF.
        </Alert>
      )}

      <Table striped hover responsive="sm">
        <thead>
          <tr>
            <th>Hospital</th>
            <th>Acesso</th>
            <th className={hospitalsCss.tableActions}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {hospitals.map((hospital, index) => (
            <tr key={index}>
              <td>{hospital.name}</td>
              <td>
                <Badge
                  bg={AccessRequestStatusLabel[hospital.accessRequestStatus]}
                >
                  {AccessRequestStatus[hospital.accessRequestStatus]}
                </Badge>
              </td>
              <td>
                {showRequestButton(hospital.accessRequestStatus) && (
                  <Button
                    className={hospitalsCss.requestAccessButton}
                    variant="outline-primary"
                    size="sm"
                    onClick={() => requestAccess(hospital.id)}
                  >
                    {requestingAccess && (
                      <div>
                        <Spinner animation="border" size="sm" /> Solicitando...
                      </div>
                    )}
                    {!requestingAccess && <div>Solicitar acesso</div>}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default Hospitals
