import { FunctionComponent } from 'react'
import classnames from 'classnames'

// https://github.com/Hacker0x01/react-datepicker
import ReactDatePicker from 'react-datepicker'
import ptBr from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'

import datePickerCss from './datePicker.module.scss'

interface DatePickerProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value?: Date) => void
  name: string
  value: Date
  placeholder: string
}

const DatePicker: FunctionComponent<DatePickerProps> = ({
  onChange,
  name,
  value,
  placeholder,
}: DatePickerProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    date: Date
  ) => {
    event.currentTarget.name = name
    onChange(event, date)
  }

  return (
    <ReactDatePicker
      name={name}
      dateFormat={'dd/MM/yyyy'}
      selected={value}
      onChange={(date: Date, event: React.ChangeEvent<HTMLInputElement>) =>
        handleChange(event, date)
      }
      locale={ptBr}
      placeholderText={placeholder}
      wrapperClassName={datePickerCss.datePickerWrapper}
      className={classnames('mb-2', 'mr-sm-2', 'form-control')}
    />
  )
}

export default DatePicker
