import { Doctor, NewOrUpdatedDoctor } from 'features/doctors/types'
import clientApi from '../../api/clientApi'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

// Consulta os médicos relacionados com o hospital do admin que está logado
export const getDoctorsRelatedToHospital = async (): Promise<Doctor[]> => {
  const { data } = await clientApi.get<Doctor[]>(
    `${apiEndpoint}/doctors/hospital`
  )
  return data
}

// Consulta médico vinculado ao usuário que está logado
export const getDoctorFromLoggedUser = async (): Promise<{
  doctorCreated: boolean
  doctor: Doctor
}> => {
  const { data } = await clientApi.get<{
    doctorCreated: boolean
    doctor: Doctor
  }>(`${apiEndpoint}/doctors/me`)

  return data
}

// Cadastra o usuário como médico
export const create = async (doctor: NewOrUpdatedDoctor): Promise<Doctor> => {
  const { data } = await clientApi.post(`${apiEndpoint}/doctors`, doctor)
  return data
}

// Atualiza os dados do médico
export const update = async (doctor: NewOrUpdatedDoctor): Promise<Doctor> => {
  const { data } = await clientApi.patch(`${apiEndpoint}/doctors`, doctor)
  return data
}
