import { FunctionComponent } from 'react'
import { Navbar } from 'react-bootstrap'

import headerOutsideCss from './headerOutside.module.scss'
import logo from 'assets/images/logo.png'

/**
 * Header used when the user is not logged in
 */
const HeaderOutside: FunctionComponent = () => (
  <Navbar expand="lg" className={headerOutsideCss.header}>
    <img
      src={logo}
      className={headerOutsideCss.logo}
      alt="Repasse Médico logo"
    ></img>
  </Navbar>
)

export default HeaderOutside
