import { FunctionComponent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConfirmationModal: FunctionComponent<{
  message: string;
  btnMessage: string;
  btnVariant?: "outline-danger" | "primary";
  onClose: () => void;
  onConfirmation: () => void;
}> = ({ message, btnMessage, btnVariant, onClose, onConfirmation }) => {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Por favor, confirme.</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant={btnVariant || "primary"} onClick={onConfirmation}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
