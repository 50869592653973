export enum UpdateAccessRequestStatus {
  APPROVED = 'APPROVED' as any,
  DENIED = 'DENIED' as any,
  REVOKED = 'REVOKED' as any,
  REQUESTED = 'REQUESTED' as any,
  NOT_REQUESTED = 'NOT_REQUESTED' as any,
}

export enum AccessRequestStatus {
  APPROVED = 'Aprovado' as any,
  DENIED = 'Negado' as any,
  REVOKED = 'Revogado' as any,
  REQUESTED = 'Solicitado' as any,
  NOT_REQUESTED = 'Não solicitado' as any,
}

export enum AccessRequestStatusLabel {
  APPROVED = 'success' as any,
  DENIED = 'danger' as any,
  REVOKED = 'warning' as any,
  REQUESTED = 'primary' as any,
  NOT_REQUESTED = 'secondary' as any,
}

export type AccessRequest = {
  id: number
  doctorName: string
  status: AccessRequestStatus
}
