export const exportarCsv = (arquivo: any, nomeArquivo: string) => {
  var fileName = nomeArquivo + '.csv'
  var contentType = 'text/csv;charset=utf-8'
  var linkElement = createLinkElement(arquivo, fileName, contentType)

  let clickEvent = createClickEvent()

  linkElement.dispatchEvent(clickEvent)
}

const createLinkElement = (
  file: File,
  fileName: string,
  contentType: string
) => {
  let blob = new Blob([file], { type: contentType })
  let url = window.URL.createObjectURL(blob)

  let linkElement = document.createElement('a')
  linkElement.setAttribute('href', url)
  linkElement.setAttribute('download', fileName)

  return linkElement
}

const createClickEvent = () => {
  let clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  })

  return clickEvent
}
