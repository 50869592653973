export enum ConvenioOptions {
  SUS = 'SUS',
  NAO_SUS = 'Não SUS',
}

/**
 * PAGO - Tudo que tem o título baixado ou seja de fato pago.
 * PENDENTE - Tudo que gerou repasse, mas o setor de repasse médico não mexeu ainda. (FORA DE REPASSE)
 * PROCESSANDO - Tudo que está DENTRO DE REPASSE Aberto ou DENTRO de Repasse Fechado sem título
 * LIBERADO - Tudo que está DENTRO DE REPASSE Fechado, mas só não foi dado baixa no título
 */
export enum TransferStatus {
  PAGO = 'PAGO',
  LIBERADO = 'LIBERADO',
  PROCESSANDO = 'PROCESSANDO',
  PENDENTE = 'PENDENTE',
  GLOSADO = 'Glosado',
}

export type Transfer = {
  hospitalTransferId: string
  numeroAtendimento: number | null
  numeroRepasseTerceiro: number | null
  status: string
  descricaoProcedimento: string | null
  observacaoRepasse: string | null
  valorProcedimento: number
  valorRepasse: number
  convenio: string | null
  descricaoConvenio: string | null
  descricaoStatus: string | null
  dataProcedimento: string
  dataAlta: string
  dataProtocolo: string
  dataFechamentoRepasse: string | null
  dataContabil: string
}
