import { FunctionComponent, useEffect, useState } from 'react'
import { Container, Form, Button, Spinner, Alert } from 'react-bootstrap'

import * as doctorApi from './api.client'
import registrationCss from './style.module.scss'
import { formatCpf, validateCpf } from 'utilities/cpf.util'
import { removeAlphabeticCharacteres } from 'utilities/string.util'
import { NewOrUpdatedDoctor } from './types'
import { getUsername } from '../user/auth'
import { AppMessage } from '../../utilities/messages'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'

const DoctorRegistration: FunctionComponent = () => {
  const [doctor, setDoctor] = useState<NewOrUpdatedDoctor>({ cpf: '' })
  const [doctorName, setDoctorName] = useState<string>()
  const [updating, setUpdating] = useState(false)
  const [doctorAlreadyCreated, setDoctorAlreadyCreated] = useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })

  useEffect(() => {
    const name = getUsername()
    if (name) {
      setDoctorName(name)
    }
    loadDoctor()
  }, [])

  const loadDoctor = async () => {
    try {
      const { doctorCreated, doctor } =
        await doctorApi.getDoctorFromLoggedUser()

      if (doctorCreated) {
        setDoctor(doctor)
        setDoctorAlreadyCreated(true)
      }
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: 'Erro ao buscar os dados do médico.',
      })
    }
  }

  const createOrUpdateDoctor = async () => {
    setUpdating(true)

    try {
      const updatedCpf = removeAlphabeticCharacteres(formatCpf(doctor.cpf))

      const { cpf } = doctorAlreadyCreated
        ? await doctorApi.update({ cpf: updatedCpf })
        : await doctorApi.create({ cpf: updatedCpf })

      setDoctor({ ...doctor, cpf })
      setDoctorAlreadyCreated(true)
      setAppMessage({
        show: true,
        success: true,
        message: 'Dados atualizados com sucesso!',
      })
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: `Erro ao atualizar o médico: ${(error as any).messages.join(
          ', '
        )}`,
      })
    } finally {
      setUpdating(false)
    }
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setDoctor({ ...doctor, [name]: value })
  }

  return (
    <Container fluid className={registrationCss.container}>
      <h3 className={registrationCss.title}>Médico</h3>

      {!doctorAlreadyCreated && (
        <Alert variant="warning">
          Para poder solicitar o acesso aos repasses, cadastre seu CPF.
        </Alert>
      )}

      {appMessage.show && (
        <Alert
          variant={appMessage.success ? 'success' : 'danger'}
          style={{ marginTop: 20 }}
          onClose={() => setAppMessage({ show: false })}
          dismissible
        >
          {appMessage.message}
        </Alert>
      )}

      <Form>
        <Form.Group className={registrationCss.formGroup}>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            name="name"
            className={registrationCss.formControl}
            value={doctorName}
            disabled={true}
            onChange={handleFormChange}
          />
        </Form.Group>

        <Form.Group className={registrationCss.formGroup}>
          <Form.Label>CPF</Form.Label>
          <Form.Control
            type="text"
            name="cpf"
            value={formatCpf(doctor.cpf)}
            className={registrationCss.formControl}
            onChange={handleFormChange}
          />

          {!validateCpf(formatCpf(doctor.cpf)) && (
            <span className={registrationCss.inputError}>
              Informe um CPF válido
            </span>
          )}
        </Form.Group>
      </Form>

      <Button
        className={registrationCss.button}
        disabled={!validateCpf(formatCpf(doctor.cpf))}
        onClick={createOrUpdateDoctor}
      >
        {updating && (
          <div>
            <Spinner size="sm" animation="border" variant="dark" /> Salvar
          </div>
        )}

        {!updating && (
          <div>
            <SaveIcon /> Salvar
          </div>
        )}
      </Button>
    </Container>
  )
}

export default DoctorRegistration
