import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import {
  Container,
  Table,
  Button,
  Row,
  Col,
  Spinner,
  Alert,
} from 'react-bootstrap'

import adminHospitalCss from './style.module.scss'
import * as api from './api.client'
import * as accessRequestApi from '../accessRequest/api.client'
import { Plus, Trash } from 'react-bootstrap-icons'
import ConfirmationModal from '../../components/modal/confirmationModal'
import { HospitalDoctor } from './types'
import { AppMessage } from '../../utilities/messages'

const HospitalDoctors: FunctionComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [doctors, setDoctors] = useState<HospitalDoctor[]>([])
  const [showRevokeAccessConfirmation, setShowRevokeAccessConfirmation] =
    useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })
  const [revokingAccessDoctor, setRevokingAccessDoctor] = useState(false)
  const [revokingAccessDoctorId, setRevokingAccessDoctorId] = useState<number>()

  const showDoctorAddedMessage = useCallback(() => {
    if (location.state && location.state.newDoctorAdded) {
      setAppMessage({
        show: true,
        success: true,
        message: location.state.userNotified
          ? `Médico adicionado com sucesso. Enviamos um email para o médico (${location.state.email}) solicitando a confirmação do cadastro.`
          : 'Médico adicionado com sucesso. O usuário não foi notificado, mas você poderá fazer isso a qualquer momento.',
      })
    }
  }, [location.state])

  useEffect(() => {
    showDoctorAddedMessage()
    getDoctors()
  }, [showDoctorAddedMessage])

  const getDoctors = async () => {
    try {
      const doctors = await api.getDoctors()
      setDoctors(doctors)
    } catch (error) {
      setAppMessage({
        show: true,
        message: `Erro ao buscar os médicos: ${(error as any).messages.join(
          ', '
        )}`,
      })
    }
  }

  const addDoctor = () => navigate('/doctors/new')

  const revokeDoctorAccess = async () => {
    if (!revokingAccessDoctorId) {
      return
    }

    try {
      setRevokingAccessDoctor(true)
      await accessRequestApi.revokeAccessRequestByDoctor(revokingAccessDoctorId)
      await getDoctors()
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: 'Erro ao revogar o acesso do médico aos repasses do hospital.',
      })
    } finally {
      setRevokingAccessDoctor(false)
      setShowRevokeAccessConfirmation(false)
      setRevokingAccessDoctorId(undefined)
    }
  }

  return (
    <Container fluid className={adminHospitalCss.container}>
      {appMessage.show && (
        <Alert
          variant={appMessage.success ? 'success' : 'danger'}
          style={{ marginTop: 20 }}
        >
          {appMessage.message}
        </Alert>
      )}

      {showRevokeAccessConfirmation && (
        <ConfirmationModal
          message="Você deseja realmente revogar o acesso do médico aos repasses do hospital?"
          btnMessage="Revogar acesso"
          btnVariant="outline-danger"
          onClose={() => setShowRevokeAccessConfirmation(false)}
          onConfirmation={revokeDoctorAccess}
        />
      )}

      <Row>
        <Col>
          <h3 className={adminHospitalCss.title}>Médicos</h3>
        </Col>

        <Col className={adminHospitalCss.header}>
          <Button size="sm" onClick={addDoctor}>
            <Plus size={26} />{' '}
            <span className={adminHospitalCss.txtButtonAddDoctor}>
              Add médico
            </span>
          </Button>
        </Col>
      </Row>

      <Table striped hover responsive="sm">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th className={adminHospitalCss.tableActions}></th>
          </tr>
        </thead>
        <tbody>
          {doctors.map((doctor, index) => (
            <tr key={index}>
              <td>{doctor.name}</td>
              <td>{doctor.email}</td>
              <td>
                <Button
                  className={adminHospitalCss.revokeAccessButton}
                  variant="outline-danger"
                  size="sm"
                  onClick={() => {
                    setShowRevokeAccessConfirmation(true)
                    setRevokingAccessDoctorId(doctor.id)
                  }}
                >
                  {revokingAccessDoctor &&
                    revokingAccessDoctorId === doctor.id && (
                      <div>
                        <Trash size={16} /> <span>Revogando... </span>
                        <Spinner animation="border" size="sm" />
                      </div>
                    )}
                  {(!revokingAccessDoctor ||
                    revokingAccessDoctorId !== doctor.id) && (
                    <div>
                      <Trash size={16} /> <span>Revogar acesso</span>
                    </div>
                  )}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default HospitalDoctors
