import { HospitalAvailable } from './types'
import clientApi from '../../api/clientApi'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

export const getAvailableHospitals = async (): Promise<HospitalAvailable[]> => {
  const { data } = await clientApi.get<HospitalAvailable[]>(
    `${apiEndpoint}/hospitals/available`
  )
  return data
}
