import { FunctionComponent } from 'react'

import footerStyle from './footer.module.scss'

const Footer: FunctionComponent<{ signedIn?: boolean }> = ({ signedIn }) => (
  <div className={signedIn ? footerStyle.signedIn : footerStyle.signedOut}>
    © {new Date().getFullYear()} repassemedico.com.br. Todos direitos
    reservados.
  </div>
)

export default Footer
