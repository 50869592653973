import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Container,
  Button,
  Row,
  Form,
  Breadcrumb,
  Spinner,
  Alert,
} from 'react-bootstrap'

import * as api from './api.client'
import hospitalsCss from './style.module.scss'
import { NewHospital } from './types'
import { formatCNPJ } from '../../utilities/cnpj.util'
import { removeAlphabeticCharacteres } from '../../utilities/string.util'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { AppMessage } from '../../utilities/messages'

const newHospitalInitialState: NewHospital = {
  name: '',
  cnpj: '',
  hospitalApiUrl: '',
  enabled: false,
}

const AddHospital: FunctionComponent = () => {
  const navigate = useNavigate()
  const [hospital, setHospital] = useState(newHospitalInitialState)
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [saving, setSaving] = useState(false)
  const [appMessage, setAppMessage] = useState<AppMessage>({ show: false })

  const addHospital = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formIsValid = event.currentTarget.checkValidity()

    if (!formIsValid) {
      setShowFormErrors(true)
      return
    }

    setSaving(true)

    try {
      const { id: hospitalId } = await api.addHospital({
        ...hospital,
        cnpj: removeAlphabeticCharacteres(hospital.cnpj),
      })

      navigate(`/hospitals/${hospitalId}`)
    } catch (error) {
      setAppMessage({
        show: true,
        success: false,
        message: `Erro ao adicionar o hospital: ${(error as any).messages.join(
          ', '
        )}`,
      })
    } finally {
      setSaving(false)
    }
  }

  const handleFormChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = target

    setHospital({ ...hospital, [name]: type === 'checkbox' ? checked : value })
  }

  return (
    <Container fluid className={hospitalsCss.container}>
      {appMessage.show && (
        <Alert
          variant="danger"
          style={{ marginTop: 20 }}
          onClose={() => setAppMessage({ show: false })}
          dismissible
        >
          {appMessage.message}
        </Alert>
      )}

      <Row>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/hospitals')}>
            Hospitais
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add hospital</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Form noValidate validated={showFormErrors} onSubmit={addHospital}>
        <Form.Group className="mb-3" controlId="formNome">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nome"
            name="name"
            value={hospital.name}
            onChange={handleFormChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe o nome do hospital.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCNPJ">
          <Form.Label>CNPJ</Form.Label>
          <Form.Control
            type="text"
            placeholder="CNPJ"
            name="cnpj"
            value={formatCNPJ(hospital.cnpj)}
            onChange={handleFormChange}
            minLength={18}
            maxLength={18}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe um CNPJ válido.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formHospitalApiUrl">
          <Form.Label>URL API Hospital</Form.Label>
          <Form.Control
            type="text"
            placeholder="URL API Hospital"
            name="hospitalApiUrl"
            value={hospital.hospitalApiUrl}
            onChange={handleFormChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Informe a URL da API do hospital.
          </Form.Control.Feedback>
          <Form.Text className="text-muted">
            Endereço da API que é instalada no infraestrutura do hospital, para
            integrar os repasses com a API do repasse médico.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formHospitalAtivo">
          <Form.Check
            type="switch"
            label="Hospital ativo"
            name="enabled"
            checked={hospital.enabled}
            onChange={handleFormChange}
          />
          <Form.Text className="text-muted">
            Só habilite quando a integração com o hospital estiver validada pela
            equipe de desenvolvimento.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          {saving && (
            <div>
              <SaveIcon />{' '}
              <span className={hospitalsCss.txtButtonAddHospital}>Salvar</span>{' '}
              <Spinner animation="border" size="sm" />{' '}
            </div>
          )}
          {!saving && (
            <div>
              <SaveIcon />{' '}
              <span className={hospitalsCss.txtButtonAddHospital}>Salvar</span>
            </div>
          )}
        </Button>
      </Form>
    </Container>
  )
}

export default AddHospital
