import { AxiosResponse } from 'axios'
import clientApi from '../../api/clientApi'
import { HospitalAdmin, HospitalDoctor, NewHospitalDoctor } from './types'

const apiEndpoint = process.env.REACT_APP_REPASSE_API_ENDPOINT

export const getDoctors = async (): Promise<HospitalDoctor[]> => {
  const { data } = await clientApi.get<HospitalDoctor[]>(
    `${apiEndpoint}/doctors/hospital`
  )

  return data
}

export const getHospitalAdmin = async (): Promise<HospitalAdmin> => {
  const { data } = await clientApi.get<HospitalAdmin>(
    `${apiEndpoint}/hospitals/admins/me`
  )

  return data
}

export const addDoctor = async (
  hospital: NewHospitalDoctor
): Promise<HospitalDoctor> => {
  const { data } = await clientApi.post<
    NewHospitalDoctor,
    AxiosResponse<HospitalDoctor>
  >(`${apiEndpoint}/doctors/hospital`, hospital)

  return data
}
